/*global $, jQuery, Modernizr, document, Ondigo */

(function ($) {
    'use strict';

    // class definition
    Ondigo.CustomerQuotes = function (element) {
        var _instance = this;

        this.$customerQuotes = $(element);
        this.$navLeft = $('.onIconArrowCircleLeft', this.$customerQuotes);
        this.$navRight = $('.onIconArrowCircleRight', this.$customerQuotes);
        this.$quotesWrapper = $('.quotes', this.$customerQuotes);
        this.$dotsWrapper = $('.dots', this.$customerQuotes);
        this.$dots = null;
        this.$quotes = $('.quote', this.$customerQuotes);
        this.active = 0;
        this.quoteCount = this.$quotes.length;
        this.minHeight = 200;
        this.extraMinHeight = 0;
        this.minHeightToBeat = 180;
        this.isAnimating = false;

        this.init();
    };

    Ondigo.CustomerQuotes.prototype = {
        init: function () {
            var _scope = this;

            if (Ondigo.Utils.getWindowInnerWidth() < 768) {
                this.minHeightToBeat = 0;
                this.extraMinHeight = 0;
            }
            else if (Ondigo.Utils.getWindowInnerWidth() < 1000) {
                this.minHeightToBeat = 180;
                this.extraMinHeight = 80;
            }
            else {
                this.minHeightToBeat = 180;
                this.extraMinHeight = 80;
            }

            var dots = '';

            for (var i = 0; i < this.quoteCount; i++) {
                if (i === 0)
                    dots += '<div class="dot active"></div>';
                else
                    dots += '<div class="dot"></div>';
            }
            this.$dotsWrapper.append(dots);

            _scope.setBoxHeight();

           /* if (_scope.$quotes.eq(_scope.active).height() > this.minHeightToBeat) {
                _scope.$quotesWrapper.css('min-height', _scope.$quotes.eq(_scope.active).height());
            }*/

            this.$navLeft.on('click', function (e) {
                _scope.switchQuote(-1);
            });
            this.$navRight.on('click', function (e) {
                _scope.switchQuote(1);
            });

            if (Ondigo.Utils.hasTouchscreen()) {
                this.$customerQuotes.swipe({
                    swipeStatus: $.proxy(_scope.swipeSlide, this),
                    threshold: 65,
                    preventDefaultEvents: false,
                    maxTimeThreshold: 2500,
                    fingers: 'all',
                    allowPageScroll: 'vertical'
                });
            }

            $(window).on('resize', function () {
                _scope.$quotesWrapper.removeAttr('style');
                /*if (_scope.$quotes.eq(_scope.active).height() > _scope.minHeightToBeat) {
                    _scope.$quotesWrapper.css('min-height', _scope.$quotes.eq(_scope.active).height());
                }*/
                if (Ondigo.Utils.getWindowInnerWidth() < 768) {
                    _scope.minHeightToBeat = 0;
                    _scope.extraMinHeight = 0;
                }
                else if (Ondigo.Utils.getWindowInnerWidth() < 1000) {
                    _scope.minHeightToBeat = 180;
                    _scope.extraMinHeight = 80;
                }
                else {
                    _scope.minHeightToBeat = 180;
                    _scope.extraMinHeight = 80;
                }
                _scope.setBoxHeight();
                //_scope.setMinHeight();
            });

            //_scope.setMinHeight();

            this.$dots = $('.dot', this.$customerQuotes);
        },
        swipeSlide: function (event, phase, direction, distance) {
            if (phase === 'end') {
                if (direction === null) {
                    $(event.target).click();
                }
                else {
                    if (direction === 'left') {
                        this.switchQuote(1);
                    }
                    else {
                        this.switchQuote(-1);
                    }
                }
            }
        },
        switchQuote: function (value) {
            var _scope = this;

            if (!this.isAnimating) {
                this.isAnimating = true;

                this.$dots.eq(this.active).removeClass('active');
                this.$quotes.eq(this.active).fadeOut();

                this.active += value; if (this.active < 0) {
                    this.active = this.quoteCount-1;
                }
                else if (this.active > this.quoteCount-1) {
                    this.active = 0;
                }
                this.$quotes.eq(this.active).fadeIn(function () {
                    //_scope.setMinHeight();
                    _scope.isAnimating = false;
                });

                this.$dots.eq(this.active).addClass('active');
            }
        },
        setMinHeight: function () {
            var minHeight = 0;
            if (this.$quotes.eq(this.active).find('.singleQuote').height() + this.extraMinHeight < this.minHeightToBeat) {
                minHeight = 250;
            }
            else {
                minHeight = this.$quotes.eq(this.active).find('.singleQuote').height() + this.extraMinHeight;
            }
            this.$quotesWrapper.css('min-height', minHeight);
        },

        setBoxHeight: function () {
            var maxBoxHeight = 0;
            var _scope = this;

            if (Ondigo.Utils.getWindowInnerWidth() < 768) {
                this.extraMinHeight = 0;
            }
            else if (Ondigo.Utils.getWindowInnerWidth() < 1000) {
                this.extraMinHeight = 80;
            }
            else {
                this.extraMinHeight = 80;
            }

            _scope.$quotesWrapper.addClass('setHeight');
            $.each(this.$quotes, function () {
                if($(this).find('.singleQuote').height() > maxBoxHeight) {
                    maxBoxHeight = $(this).find('.singleQuote').height();
                }
            });
            _scope.$quotesWrapper.removeClass('setHeight');
            _scope.$quotesWrapper.css('min-height', (maxBoxHeight + this.extraMinHeight)+'px');
        }
    };

    // plugin definition
    $.fn.customerQuotes = function () {
        return new Ondigo.CustomerQuotes(this);
    };
    $.fn.customerQuotes.Constructor = Ondigo.CustomerQuotes;

}(window.jQuery));

$(document).ready(function () {
    'use strict';

    if ($('.customerQuotes').length) {
        $('.customerQuotes').customerQuotes();
    }
});