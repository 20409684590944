/**
 * Created by mkoehler on 13.11.18.
 */

export default class HelpCasketPositioner {

    constructor() {
        let _scope = this;
        this.helpcasket = document.getElementsByClassName('help-casket');

        if(this.helpcasket.length) {

            for (let help of Array.from(this.helpcasket)) {
                _scope.init(help);
            }
        }
    }

    init (wrapper) {
        let _scope = this,
            checkbox = wrapper.querySelectorAll('input[type="checkbox"]')[0],
            shadow = wrapper.querySelectorAll('.help-content-shadow')[0];

        // close overlay hint on outside click
        checkbox.addEventListener('click',function (e) {
            _scope.overlayBackgroundPositionHandler();
        });

        // close overlay hint on outside click
        shadow.addEventListener('click',function (e) {
            _scope.overlayBackgroundPositionHandler();

            checkbox.checked = false;
        });
    }

    overlayBackgroundPositionHandler () {
        var overlayClass = 'hasOverlay';
        if (document.body.classList.contains(overlayClass)) {
            document.body.classList.remove(overlayClass);
            window.scroll(0, parseInt(document.getElementsByClassName('overflowWrapper')[0].style.top) * -1);
            document.getElementsByClassName('overflowWrapper')[0].removeAttribute("style");
        } else {
            document.getElementsByClassName('overflowWrapper')[0].style.top = '-' + window.pageYOffset + 'px';
            document.body.classList.add(overlayClass);
        }
    }
}