/**
 * Created by rrentz on 06.06.16.
 */
export default class LazyUnveil {
    constructor () {
        this.init();
    }

    init() {
        document.addEventListener('lazyafterunveil', function(e){
            var img = e.detail,
                datasrc = img.getAttribute('data-src'),
                src = img.getAttribute('src');

            if (src === null || src === '' || src === undefined) {
                img.src = datasrc;
            }
        });
    }
}