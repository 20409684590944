/**
 * Created by mkoehler on 29.08.18.
 */

import videojs from 'video.js/dist/video';

class VideoHandler {

    static initVideoJs () {
        let videos = document.querySelectorAll('.video-wrapper');

        if(videos.length) {

            for(let video of Array.from(videos)) {
                if(!$(video).closest('.lightboxOverlayWrapper').length) {
                    let videoJsInstance = video.getElementsByTagName('video')[0].getAttribute('id'),
                        playButton = video.getElementsByClassName('onIconPlay')[0];

                    // for lightbox videos
                    if(videoJsInstance === null) {
                        videoJsInstance = video.getElementsByTagName('video')[0].getAttribute('data-id');
                        video.getElementsByTagName('video')[0].id = videoJsInstance;
                    }

                    VideoHandler.handleAutoPlay(videoJsInstance, playButton, video);
                    VideoHandler.handleResize(videoJsInstance, playButton, video);
                }
            }
        }
    }

    static handleAutoPlay (videoJsInstance, playButton, video) {
        let el,
            srcType = video.getAttribute('data-src-type'),
            autoplay = video.getAttribute('data-autoplay') === '1',
            videoSrc = video.getElementsByTagName('video')[0].getAttribute('data-src'),
            options = VideoHandler.getOptions(srcType, autoplay, videoSrc);

        // initialize videos
        if(video.getAttribute('data-init') === '0') {
            el = videojs(videoJsInstance, options);

            // src can only set inside options with m3u8 videos
            VideoHandler.setMp4Src(el, videoSrc, srcType);

            // init playbutton for mobile devices
            el.ready(function () {

                if(autoplay) {
                    // init playbutton for autoplay videos only on mobile devices
                    if(Ondigo.Utils.getWindowInnerWidth() < 768) {
                        VideoHandler.initPlayButton(el, playButton);
                    }
                } else {
                    VideoHandler.initPlayButton(el, playButton);
                }

                video.setAttribute('data-init', '1');
            });

            el.on('ended', function () {

                if(autoplay) {

                    // check if whole video has ended (not only one part)
                    if(el.currentTime() >= el.duration()) {

                        // loop without jiggle
                        el.currentTime(0);

                        if(Ondigo.Utils.elementInViewport(video)) {
                            el.play().catch((err) => {
                                console.log(err);
                            });
                        }
                    } else if(Ondigo.Utils.getWindowInnerWidth() < 768) {
                        el.currentTime(0);
                        VideoHandler.initPlayButton(el, playButton);
                        playButton.style.display = 'block';
                    }
                } else {
                    el.currentTime(0);
                    VideoHandler.initPlayButton(el, playButton);
                    playButton.style.display = 'block';
                }
            });
        } else {
            el = videojs(videoJsInstance);
        }

        // handle autoplay
        if(autoplay) {
            // start only on non mobile devices
            if(Ondigo.Utils.getWindowInnerWidth() >= 768) {

                // start only if in viewport
                if(Ondigo.Utils.elementInViewport(video)) {

                    // video is paused
                    if(el.el().classList.contains('vjs-paused') || el.paused()) {

                        el.ready(function() {
                            playButton.style.display = 'none';

                            el.play().catch((err) => {
                                console.log(err);
                            });

                            // fake pagescroll for firefox cause its a bastard
                            // -> firefox needs kind of user interaction
                            if(navigator.userAgent.search("Firefox") > -1 && video.getAttribute('data-firefox-fix') !== '1') {
                                setTimeout(function () {
                                    if(el.el().classList.contains('vjs-paused') || el.paused()) {
                                        window.scrollTo(window.scrollX, window.scrollY - 1);
                                        window.scrollTo(window.scrollX, window.scrollY + 1);
                                        video.setAttribute('data-firefox-fix', '1');
                                    }
                                }, 1000);
                            }
                        });
                    }
                } else {
                    // allow video pause only if video is playing
                    el.ready(function() {

                        if (!el.paused() && !el.ended()) {
                            el.pause();
                        }
                    });
                }

            } else {
                // init playbutton for mobile devices
                el.ready(function () {

                    if (!el.paused() && !el.ended()) {
                        el.pause();
                        VideoHandler.initPlayButton(el, playButton);
                        playButton.style.display = 'block';
                    }
                });
            }
        }
    }

    static setMp4Src (video, videosrc, videotype) {
        if (videotype === 'mp4') {

            video.src({
                src: videosrc,
                type: 'video/mp4'
            })
        }
    }

    static getOptions (videotype, videoautoplay, videosrc) {
        let options;

        if(videoautoplay) {
            if(videotype === 'm3u8') {
                options = {
                    html5: {
                        hls: {
                            overrideNative: true
                        },
                        nativeAudioTracks: false,
                        nativeVideoTracks: false,
                    },
                    muted: videoautoplay,
                    playsinline: true,
                    preload: 'none',
                    controls: false,
                    sourceOrder: true,
                    language: document.documentElement.lang,
                    sources: [{
                        src: videosrc,
                        type: 'application/x-mpegURL'
                    }, {
                        src: videosrc.replace('.m3u8', '720p.mp4'),
                        type: 'video/mp4'
                    }]
                };
            } else if(videotype === 'mp4') {
                options = {
                    muted: videoautoplay,
                    preload: 'none',
                    controls: false,
                    playsinline: true,
                    language: document.documentElement.lang
                };
            }
        } else {
            if(videotype === 'm3u8') {
                options = {
                    html5: {
                        hls: {
                            overrideNative: true
                        },
                        nativeAudioTracks: false,
                        nativeVideoTracks: false,
                    },
                    muted: videoautoplay,
                    playsinline: true,
                    preload: 'none',
                    language: document.documentElement.lang,
                    controls: true,
                    sourceOrder: true,
                    sources: [{
                        src: videosrc,
                        type: 'application/x-mpegURL'
                    }, {
                        src: videosrc.replace('.m3u8', '720p.mp4'),
                        type: 'video/mp4'
                    }]
                };
            } else if(videotype === 'mp4') {
                options = {
                    muted: videoautoplay,
                    controls: true,
                    preload: 'none',
                    language: document.documentElement.lang,
                    playsinline: true
                };
            }
        }

        return options;
    }

    static handleResize (videoJsInstance, playButton, video) {
        let event = Ondigo.Utils.hasTouchscreen() ? 'orientationchange' : 'resize';

        window.addEventListener(event, function () {
            setTimeout(function () {
                VideoHandler.handleAutoPlay(videoJsInstance, playButton, video);
            }, 100);
        });
    }

    static initPlayButton (player, playButton) {
        if(playButton.getAttribute('data-init') !== 1) {

            playButton.addEventListener('click', function () {
                playButton.style.display = 'none';

                player.play().catch((err) => {
                    console.log(err);
                    player.pause();
                    playButton.style.display = 'none';
                });
            });

            playButton.setAttribute('data-init', '1');
        }
    }

    static stopVideos () {
        let videos = document.querySelectorAll('.video-wrapper');

        if(videos.length) {

            for(let video of Array.from(videos)) {
                let videoJsInstance = video.getElementsByTagName('video')[0].getAttribute('id');

                if(video.getAttribute('data-init') === '1') {
                    let el = videojs(videoJsInstance);

                    el.ready(function() {

                        if (!el.paused() && !el.ended()) {
                            el.pause();
                        }
                    });
                }
            }
        }
    }
}

export {
    VideoHandler
};