import { DomUtilities } from "../general/utils";

/**
 * Created by mkoehler on 25.02.21.
 */

export default class SpaceRequestConditions {
    constructor () {
        this.forms = document.getElementsByClassName('ondigoform-t3');

        if(this.forms.length) {
            for(let form of Array.from(this.forms)) {
                this.init(form);
            }
        }
    }

    init (form) {
        this.handlePetType(form);
        this.handleC19Vaccination(form);
        this.handlejudicialSupportRequest(form);
        this.handleSupportDegreeAmbulance(form);
        this.handleSupportDegreeStationary(form);
        this.handleUtilityServiceOxygen(form);
        this.handleUtilityServiceOthers(form);
    }

    handlePetType (form) {
        let radios = form.querySelectorAll('[data-js="pet"]');

        if(radios.length) {
            let petTypes = form.querySelectorAll('[data-js="pet-type"]');

            for (let radio of Array.from(radios)) {
                radio.addEventListener('change', function () {
                    if(petTypes.length) {
                        for(let petType of Array.from(petTypes)) {
                            if(radio.value === 'yes') {
                                DomUtilities.closest(petType, '.form-group').classList.add('visible');
                            } else {
                                DomUtilities.closest(petType, '.form-group').classList.remove('visible');
                            }
                        }
                    }
                });
            }
        }
    }

    handleC19Vaccination (form) {
        let radios = form.querySelectorAll('[data-js="c19"]');

        if(radios.length) {
            let vaccinationTypes = form.querySelectorAll('[data-js="c19-type"]'),
                vaccinationDates = form.querySelectorAll('[data-js="c19-date"]'),
                vaccinationDateTexts = form.getElementsByClassName('c19-date-text');

            for (let radio of Array.from(radios)) {
                radio.addEventListener('change', function () {
                    if(radio.value === 'yes') {
                        if(vaccinationTypes.length) {
                            for(let vaccinationType of Array.from(vaccinationTypes)) {
                                DomUtilities.closest(vaccinationType, '.form-group').classList.add('visible');
                            }
                        }
                    } else {
                        if(vaccinationTypes.length) {
                            for(let vaccinationType of Array.from(vaccinationTypes)) {
                                DomUtilities.closest(vaccinationType, '.form-group').classList.remove('visible');

                                if(vaccinationType.checked) {
                                    vaccinationType.checked = false;
                                }
                            }
                        }

                        if(vaccinationDateTexts.length) {
                            for(let vaccinationDateText of Array.from(vaccinationDateTexts)) {
                                vaccinationDateText.classList.remove('visible');
                            }
                        }

                        if(vaccinationDates.length) {
                            for(let vaccinationDate of Array.from(vaccinationDates)) {
                                vaccinationDate.value = '';
                                DomUtilities.closest(vaccinationDate, '.form-group').classList.remove('visible');
                            }
                        }
                    }
                });
            }

            if(vaccinationTypes.length) {
                for (let vaccinationType of Array.from(vaccinationTypes)) {
                    vaccinationType.addEventListener('change', function () {

                        if(vaccinationDateTexts.length) {
                            for(let vaccinationDateText of Array.from(vaccinationDateTexts)) {
                                vaccinationDateText.classList.add('visible');
                            }
                        }

                        if(vaccinationDates.length) {
                            for(let vaccinationDate of Array.from(vaccinationDates)) {
                                DomUtilities.closest(vaccinationDate, '.form-group').classList.add('visible');
                            }
                        }
                    });
                }
            }
        }
    }

    handlejudicialSupportRequest (form) {
        let radios = form.querySelectorAll('[data-js="judicial-support-available"]');

        if(radios.length) {
            let judicialSupportRequests = form.querySelectorAll('[data-js="judicial-support-request"]');

            for (let radio of Array.from(radios)) {
                radio.addEventListener('change', function () {
                    if(judicialSupportRequests.length) {
                        for(let judicialSupportRequest of Array.from(judicialSupportRequests)) {
                            if(radio.value === 'no') {
                                DomUtilities.closest(judicialSupportRequest, '.form-group').classList.add('visible');
                            } else {
                                DomUtilities.closest(judicialSupportRequest, '.form-group').classList.remove('visible');
                            }
                        }
                    }
                });
            }
        }
    }

    handleSupportDegreeAmbulance (form) {
        let radios = form.querySelectorAll('[data-js="support-degree-ambulance-available"]');

        if(radios.length) {
            let supportDegreeAmbulances = form.querySelectorAll('[data-js="support-degree-ambulance"]');

            for(let radio of Array.from(radios)) {
                radio.addEventListener('change', function () {
                    if(supportDegreeAmbulances.length) {
                        for(let supportDegreeAmbulance of Array.from(supportDegreeAmbulances)) {
                            if(radio.value === 'yes') {
                                DomUtilities.closest(supportDegreeAmbulance, '.form-group').classList.add('visible');
                            } else {
                                DomUtilities.closest(supportDegreeAmbulance, '.form-group').classList.remove('visible');
                            }
                        }
                    }
                });
            }
        }
    }

    handleSupportDegreeStationary (form) {
        let radiosAvailables = form.querySelectorAll('[data-js="support-degree-stationary-available"]'),
            radiosRequesteds = form.querySelectorAll('[data-js="support-degree-stationary-requested"]'),
            supportDegreeStationarys = form.querySelectorAll('[data-js="support-degree-stationary"]');

        if(radiosAvailables.length) {
            for (let radioAvailable of Array.from(radiosAvailables)) {
                radioAvailable.addEventListener('change', function () {
                    if(radiosRequesteds.length) {
                        for(let radiosRequested of Array.from(radiosRequesteds)) {
                            if(radioAvailable.value === 'yes') {
                                DomUtilities.closest(radiosRequested, '.form-group').classList.remove('visible');
                                radiosRequested.checked = false;
                            } else {
                                DomUtilities.closest(radiosRequested, '.form-group').classList.add('visible');
                            }
                        }
                    }

                    if(supportDegreeStationarys.length) {
                        for(let supportDegreeStationary of Array.from(supportDegreeStationarys)) {
                            if(radioAvailable.value === 'yes') {
                                DomUtilities.closest(supportDegreeStationary, '.form-group').classList.add('visible');
                            } else {
                                DomUtilities.closest(supportDegreeStationary, '.form-group').classList.remove('visible');
                            }
                        }
                    }
                });
            }
        }

        if(radiosRequesteds.length) {
            for(let radioRequested of Array.from(radiosRequesteds)) {
                radioRequested.addEventListener('change', function () {
                    if(supportDegreeStationarys.length) {
                        for(let supportDegreeStationary of Array.from(supportDegreeStationarys)) {
                            if(radioRequested.value === 'yes') {
                                DomUtilities.closest(supportDegreeStationary, '.form-group').classList.add('visible');
                            } else {
                                DomUtilities.closest(supportDegreeStationary, '.form-group').classList.remove('visible');
                            }
                        }
                    }
                });
            }
        }
    }

    handleUtilityServiceOxygen (form) {
        let oxygenCheckboxes = form.querySelectorAll('[data-js="oxygen"]'),
            oxygenRadios = form.querySelectorAll('[data-js="oxygen-radio"]'),
            oxygenRadioInputs = form.querySelectorAll('[data-js="utility-service-oxygen"]'),
            oxygenTexts = form.querySelectorAll('[data-js="oxygen-text"]'),
            oxygenTextInputs = form.querySelectorAll('[data-js="utility-service-oxygen-timerange"]');

        if(oxygenCheckboxes.length) {
            for(let oxygenCheckbox of Array.from(oxygenCheckboxes)) {
                oxygenCheckbox.addEventListener('click', function () {
                    if(oxygenRadios.length) {
                        for(let oxygenRadio of Array.from(oxygenRadios)) {
                            if(oxygenCheckbox.checked) {
                                DomUtilities.closest(oxygenRadio, '.form-group').classList.add('visible');
                            } else {
                                DomUtilities.closest(oxygenRadio, '.form-group').classList.remove('visible');
                                oxygenRadio.checked = false;

                                if(oxygenRadioInputs.length) {
                                    for (let oxygenRadioInput of Array.from(oxygenRadioInputs)) {
                                        oxygenRadioInput.value = '';
                                    }
                                }
                                if(oxygenTexts.length) {
                                    for (let oxygenText of Array.from(oxygenTexts)) {
                                        DomUtilities.closest(oxygenText, '.form-group').classList.remove('visible');
                                        oxygenText.value = '';
                                    }
                                }
                                if(oxygenTextInputs.length) {
                                    for (let oxygenTextInput of Array.from(oxygenTextInputs)) {
                                        oxygenTextInput.value = '';
                                    }
                                }
                            }
                        }
                    }
                });
            }
        }

        if(oxygenRadios.length) {
            for (let radio of Array.from(oxygenRadios)) {
                radio.addEventListener('change', function () {
                    if(oxygenTexts.length) {
                        for (let oxygenText of Array.from(oxygenTexts)) {
                            if(radio.value === 'timerange') {
                                DomUtilities.closest(oxygenText, '.form-group').classList.add('visible');
                            } else {
                                DomUtilities.closest(oxygenText, '.form-group').classList.remove('visible');
                                oxygenText.value = '';
                            }

                            if(oxygenTextInputs.length) {
                                for (let oxygenTextInput of Array.from(oxygenTextInputs)) {
                                    oxygenTextInput.value = oxygenText.value;
                                }
                            }
                        }
                    }
                });
            }
        }

        if(oxygenTexts.length) {
            for (let oxygenText of Array.from(oxygenTexts)) {
                oxygenText.addEventListener('change', function () {
                    if(oxygenTextInputs.length) {
                        for (let oxygenTextInput of Array.from(oxygenTextInputs)) {
                            oxygenTextInput.value = oxygenText.value;
                        }
                    }
                });
            }
        }
    }

    handleUtilityServiceOthers (form) {
        let othersCheckboxes = form.querySelectorAll('[data-js="others"]'),
            othersTexts = form.querySelectorAll('[data-js="others-text"]'),
            othersTextInputs = form.querySelectorAll('[data-js="utility-service-other"]');

        if(othersCheckboxes.length) {
            for(let othersCheckbox of Array.from(othersCheckboxes)) {
                othersCheckbox.addEventListener('click', function () {
                    if(othersTexts.length) {
                        for(let othersText of Array.from(othersTexts)) {
                            if(othersCheckbox.checked) {
                                DomUtilities.closest(othersText, '.form-group').classList.add('visible');
                            } else {
                                DomUtilities.closest(othersText, '.form-group').classList.remove('visible');
                            }
                        }
                    }
                });
            }
        }

        if(othersTexts.length) {
            for(let othersText of Array.from(othersTexts)) {
                othersText.addEventListener('change', function () {
                    if(othersTextInputs.length) {
                        for(let othersTextInput of Array.from(othersTextInputs)) {
                            othersTextInput.value = othersText.value;
                        }
                    }
                });
            }
        }
    }
}