/*global $, jQuery, Modernizr, document, Ondigo */

(function ($) {
    'use strict';

    // Class Definition
    Ondigo.ImageScroller = function (element) {
        var that = this;

        this.$imageScroller = $(element);
        this.touchMove = false;
        this.mouseStartX = 0;
        this.scrollStart = 0;

        this.init();
    };

    Ondigo.ImageScroller.prototype = {
        init: function () {

            var _instance = this;

            this.$imageScroller.on('scroll', function () {
                //Ondigo.Utils.checkImagesForLoading();
            });

            if (!Ondigo.Utils.hasTouchscreen()) {
                this.$imageScroller.on('mousedown', function (e) {
                    _instance.touchMove = true;
                    _instance.mouseStartX = e.pageX;
                    _instance.scrollStart = _instance.$imageScroller.scrollLeft();
                });
                $(document).on('mouseup', function (e) {
                    _instance.touchMove = false;
                    e.preventDefault();
                    e.stopPropagation();
                    _instance.$imageScroller.removeClass('noClicks');
                });
                $(document).on('mousemove', function (e) {
                    if (_instance.touchMove) {
                        _instance.$imageScroller.addClass('noClicks');
                        e.preventDefault();
                        e.stopPropagation();
                        _instance.$imageScroller.scrollLeft(_instance.scrollStart + (e.pageX - _instance.mouseStartX)*-1);
                    }
                });
            }
        }
    };

    // plugin definition
    $.fn.imageScroller = function () {
        return new Ondigo.ImageScroller(this);
    };
    $.fn.imageScroller.Constructor = Ondigo.ImageScroller;

}(window.jQuery));


$(document).ready(function () {
    'use strict';
    
    if ($('.imageScroller').length) {
        $.each($('.imageScroller'), function () {
            $(this).imageScroller();
        });
    }
});