export default class ResumeHandler {
    constructor() {
        let _scope = this;
        this.forms = document.getElementsByClassName('ondigoform-t3');

        if (this.forms.length) {
            for (let form of Array.from(this.forms)) {
                _scope.init(form);
            }
        }
    }

    init(form) {
        let resumeClickHandler = form.querySelectorAll('.form-group.add-resume');

        if (resumeClickHandler.length) {
            let resume3 = form.querySelectorAll('.form-group.resume-3')[0],
                resume4 = form.querySelectorAll('.form-group.resume-4')[0],
                resume5 = form.querySelectorAll('.form-group.resume-5')[0];

            resumeClickHandler[0].addEventListener('click', function () {
                if (!resume3.classList.contains('visible')) {
                    resume3.classList.add('visible');
                } else if (!resume4.classList.contains('visible')) {
                    resume4.classList.add('visible');
                } else if (!resume5.classList.contains('visible')) {
                    resume5.classList.add('visible');
                    this.classList.add('invisible');
                }
            });

            this.hideOnMinusClick(resume3, resumeClickHandler);
            this.hideOnMinusClick(resume4, resumeClickHandler);
            this.hideOnMinusClick(resume5, resumeClickHandler);
        }
    }

    hideOnMinusClick(resume, resumeClickHandler) {
        resume.getElementsByClassName('onIconMinusCircle')[0].addEventListener('click', function () {
            if (resume.classList.contains('visible')) {
                resume.classList.remove('visible');
                resumeClickHandler[0].classList.remove('invisible');
            }
        });
    }
}
