import { DomUtilities } from '../general/utils';

export default class NavigationButton {
    constructor () {
        let _scope = this;

        this.buttons = document.getElementsByClassName('nav-button');
        this.footer = document.getElementsByTagName('footer')[0];

        if(this.buttons.length) {

            for(let button of Array.from(this.buttons)) {
                _scope.init(button);
            }
        }
    }

    init (button) {

        if(DomUtilities.getWindowInnerWidth() < 1000) {
            // + 30px for more space between footer content and button on scroll
            this.footer.style.paddingBottom = parseInt(button.offsetHeight, 10) + 30 + 'px';
        } else {
            this.footer.style.paddingBottom = '';
        }
    }
}
