
export default class AccordionToggler {
    constructor () {
        var _instance = this;

        this.$page = $('html, body');
        this.$accordionElement = $('.accordion .toggler');
        this.$togglerElement = $(':not(.accordion) > .toggler');
        this.$accordionElementHeadline = $('.togglerHead', this.$accordionElement);
        this.$togglerElementHeadline = $('.togglerHead', this.$togglerElement);
        this.displayTogglerAsNormalCeOnDesktopArray = this.getSettings(this.$togglerElement);
        this.displayAccordionAsNormalCeOnDesktopArray = this.getSettings(this.$accordionElement);
        this.$window = $(window);

        this.$window.on('load', function () {
            _instance.checkIfTogglerShouldBeDisplayed();
            _instance.showAnchorTogglerContent();
        });

        this.$window.on('resize', function () {
            _instance.checkIfTogglerShouldBeDisplayed();
        });

        this.$accordionElementHeadline.on('click', function (e) {
             _instance.accordionAnimation(e);
        });

        this.$togglerElementHeadline.on('click', function (e) {
            if ($(e.target).parents('.toggler').first().parents('.tabNavigation').length) {
                if (Ondigo.Utils.getWindowInnerWidth() < 1000) {
                    _instance.accordionAnimation(e);
                }
            } else {
                _instance.togglerAnimation(e);
            }
        });

        this.$page.on('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove', function(){
            _instance.$page.stop();
            window.setTimeout(function () {
                _instance.$page.stop();
            }, 100);
        });
    }

    getSettings ($items) {
        var arr = [], i = 0;
        for (i; i < $items.length; i++) {
            arr[i] = $items.eq(i).hasClass('displayAsNormalCeOnDesktop');
        }
        return arr;
    }

    checkIfTogglerShouldBeDisplayed () {
        if (Ondigo.Utils.getWindowInnerWidth() < Ondigo.breakpointWide) {
            this.$accordionElement.removeClass('displayAsNormalCeOnDesktop');
            this.$togglerElement.removeClass('displayAsNormalCeOnDesktop');
        } else {
            var i, className;
            for (i = 0; i < this.displayTogglerAsNormalCeOnDesktopArray.length; i++) {
                className = this.displayTogglerAsNormalCeOnDesktopArray[i] === true ? 'displayAsNormalCeOnDesktop' : '';
                this.$togglerElement.eq(i).addClass(className);
            }

            for (i = 0; i < this.displayAccordionAsNormalCeOnDesktopArray.length; i++) {
                className = this.displayAccordionAsNormalCeOnDesktopArray[i] === true ? 'displayAsNormalCeOnDesktop' : '';
                this.$accordionElement.eq(i).addClass(className);
            }
        }
    }

    togglerAnimation (e) {
        var $currentToggler = $(e.target).parents('.toggler').first(),
            $currentTogglerContent = $currentToggler.find('.togglerContent').first(),
            currentTogglerSpeed = $currentToggler.data('speed') || 400;

        if (!$currentToggler.hasClass('displayAsNormalCeOnDesktop') || Ondigo.Utils.getWindowInnerWidth() < 768) {
            $currentTogglerContent.slideToggle(currentTogglerSpeed);
            $currentToggler.toggleClass('closed');
        }
    }
    accordionAnimation (e) {
        var _scope = this,
            $currentAccordion = $(e.target).parents('.toggler'),
            $openedAccordion = $currentAccordion.parents('.accordion').find('.opened'),
            $openedAccordionTabNavigation = $currentAccordion.closest('.tabNavigation').find('.opened');

        if (!$currentAccordion.hasClass('displayAsNormalCeOnDesktop')) {

            if ($currentAccordion.hasClass('closed')) {

                if ($openedAccordion.length) {
                    $openedAccordion.removeClass('opened').addClass('closed').find('.togglerContent').slideUp();
                }

                if ($openedAccordionTabNavigation.length) {
                    $openedAccordionTabNavigation.removeClass('opened').addClass('closed').find('.togglerContent').slideUp();
                }

                $currentAccordion.removeClass('closed').addClass('opened').find('.togglerContent').slideDown(400, function() {

                    if (Ondigo.Utils.getWindowInnerWidth() < 1000) {

                        window.setTimeout(function () {
                            _scope.$page.animate({ scrollTop: $currentAccordion.offset().top }, 500);
                        }, 100);
                    }
                });
            } else {
                $currentAccordion.removeClass('opened').addClass('closed').find('.togglerContent').slideUp();
            }
        }
    }
    showAnchorTogglerContent () {
        var hash = window.location.hash;

        if($(hash).length) {
            if($(hash).hasClass('toggler')) {
                this.$accordionElement.addClass('closed');
                $(hash).removeClass('closed');
                $(hash).find('.togglerContent').slideDown();
            }
        }
    }
}