/*global $, jQuery, Modernizr, document, Ondigo, Math */

(function ($) {
    'use strict';

    // class definition
    Ondigo.TabNavigation = function (element) {
        this.$tabNavigation = $(element);
        this.$tabs = this.$tabNavigation.find('.tabs h3');

        this.init();
    };

    Ondigo.TabNavigation.prototype = {
        init: function () {
            var that = this;
            this.$tabNavigation.find('.tab1').addClass('active');

            this.$tabs.on('click', function (e) {
                that.hideAndShow(e);
            });

            if (this.$tabs.length === 1) {
                this.$tabs.hide();
                this.$tabNavigation.addClass('singleTab');
                $('.tabContent', this.$tabNavigation).prepend('<h3>' + this.$tabs.text() + '</h3>');
            }
        },
        hideAndShow: function (e) {
            var tabNumber = parseInt(this.$tabs.index($(e.target)), 10) + 1;
            this.$tabNavigation.find('.active').removeClass('active');
            this.$tabNavigation.find('.tab' + tabNumber).addClass('active');
        }
    };
    // plugin definition
    $.fn.tabNavigation = function () {
        return new Ondigo.TabNavigation(this);
    };
    $.fn.tabNavigation.Constructor = Ondigo.TabNavigation;

}(window.jQuery));

$(document).ready(function () {
    'use strict';

    var $tabNavigation = $('.tabNavigation');
    if ($tabNavigation.length) {
        $.each($tabNavigation, function () {
            $(this).tabNavigation();
        });
    }
});