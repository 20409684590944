/**
 * Created by mkoehler on 12.07.17.
 */

class Cookies {
    static setCookie (cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();

        document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
    }

    static getCookie (cname) {
        let name = cname + "=",
            ca = document.cookie.split(';');
        for (let c of ca) {
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    static removeCookie (cname) {
        Cookies.setCookie(cname, "" ,-1);
    }
}

class DomHelper {
    static elementExists (elem) {
        return elem !== undefined && elem !== 'undefined' && elem !== null;
    }

    static insertAfter (newNode, referenceNode) {
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }
}

class IdentHeight {

    static setIdentHeight (elements) {

        let maxHeight,
            heights = [];

        maxHeight = 0;

        for (let item of Array.from(elements)) {
            item.style.height = '';
            heights.push(item.clientHeight);
        }

        maxHeight = DomUtilities.maxValueInArray(heights);

        for (let item of Array.from(elements)) {
            item.style.height = maxHeight + 'px';
        }
    }

    static removeIdentHeight (elements) {

        for (let item of Array.from(elements)) {
            item.style.height = '';
        }
    }
}

class WindowEvents {
    static preventDefault (e) {
        e = e || window.event;
        if (e.preventDefault)
            e.preventDefault();
        e.returnValue = false;
    }

    static preventDefaultForScrollKeys (e) {
        let keys = {37: 1, 38: 1, 39: 1, 40: 1};

        if (keys[e.keyCode]) {
            WindowEvents.preventDefault(e);
            return false;
        }
    }

    static disableScroll (elem) {
        if (elem.addEventListener) // older FF
            elem.addEventListener('DOMMouseScroll', WindowEvents.preventDefault, false);
        elem.onwheel = WindowEvents.preventDefault; // modern standard
        elem.onmousewheel = document.onmousewheel = WindowEvents.preventDefault; // older browsers, IE
        elem.ontouchstart  = WindowEvents.preventDefault; // mobile
        elem.ontouchmove  = WindowEvents.preventDefault; // mobile
        document.onkeydown  = WindowEvents.preventDefaultForScrollKeys;
    }

    static enableScroll (elem) {
        if (elem.removeEventListener) {
            elem.removeEventListener('DOMMouseScroll', WindowEvents.preventDefault, false);
        }
        elem.onmousewheel = document.onmousewheel = null;
        elem.onwheel = null;
        elem.ontouchstart  = null;
        elem.ontouchmove = null;
        document.onkeydown = null;
    }
}

class DomUtilities {
    static closest (el, selector) {
        let matchesFn;

        // find vendor prefix
        ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(function(fn) {
            if (typeof document.body[fn] === 'function') {
                matchesFn = fn;
                return true;
            }
            return false;
        });

        let parent;

        // traverse parents
        while (el) {
            parent = el.parentElement;
            if (parent && parent[matchesFn](selector)) {
                return parent;
            }
            el = parent;
        }

        return null;
    }

    static getAbsoluteElementHeight(elem) {
        return parseInt(elem.offsetHeight, 10) +
            parseInt(window.getComputedStyle(elem).getPropertyValue('margin-top'), 10) +
            parseInt(window.getComputedStyle(elem).getPropertyValue('margin-bottom'), 10) +
            parseInt(window.getComputedStyle(elem).getPropertyValue('padding-bottom'), 10);
    }

    static getWindowInnerHeight() {
        return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    static getWindowInnerWidth () {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    // element reached viewport
    static elementInViewport (el) {
        let top = el.offsetTop;
        let left = el.offsetLeft;
        let width = el.offsetWidth;
        let height = el.offsetHeight;

        while(el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top < (window.pageYOffset + window.innerHeight) &&
            left < (window.pageXOffset + window.innerWidth) &&
            (top + height) > window.pageYOffset &&
            (left + width) > window.pageXOffset
        );
    }

    // element full in viewport
    static elementFullVisible (el) {
        let top = el.offsetTop;
        let height = el.offsetHeight;

        while(el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
        }

        return (
            (top + (height / 2)) < (window.pageYOffset + window.innerHeight) &&
            (top + (height / 2)) > window.pageYOffset
        );
    }

    static isIE11 () {
        let ua = navigator.userAgent.toString().toLowerCase(),
            match = /(trident)(?:.*rv:([\w.]+))?/.exec(ua) ||/(msie) ([\w.]+)/.exec(ua)||['',null,-1];
        return match[2] !== -1;
    }

    static isAndroidDevice () {
        let ua = navigator.userAgent.toString().toLowerCase();
        return ua.indexOf("android") > -1;
    }

    static isTouchDevice () {
        return ('ontouchstart' in document.documentElement);
    }

    static maxValueInArray (array) {
        return Math.max.apply(Math, array);
    }

    static stopPageScroll (classname) {
        let wrapper = document.body;

        if(!wrapper.classList.contains(classname)) {
            wrapper.style.top = '-' + window.scrollY + 'px';
            wrapper.classList.add(classname);
        }
    }

    static enablePageScroll (classname) {
        let wrapper = document.body,
            top = parseInt(wrapper.style.top, 10) * -1;

        if(wrapper.classList.contains(classname)) {
            wrapper.classList.remove(classname);
            wrapper.style.top = '';

            window.scrollTo(0, parseInt(top, 10));
        }
    }

    static scrollToY(elementY, duration) {
        let startingY = window.pageYOffset,
            diff = elementY - startingY,
            start;

        window.requestAnimFrame(function step(timestamp) {
            if (!start) start = timestamp;
            let time = timestamp - start;
            let percent = Math.min(time / duration, 1);

            window.scrollTo(0, startingY + diff * percent);

            if (time < duration) {
                window.requestAnimFrame(step);
            }
        })
    }

    static removeClass ( el, className ) {
        if (el.classList) {
            el.classList.remove(className);
        }
        else if (hasClass(el, className)) {
            var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
            el.className = el.className.replace(reg, ' ');
        }
    };

    static hasClass (el, className) {
        if (el.classList)
            return el.classList.contains(className);
        return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
    };

    static addClass (el, className) {
        if (el.classList) {
            el.classList.add(className)
        }
        else if (!hasClass(el, className)) {
            el.className += " " + className;
        }
    };
}

export {
    Cookies,
    DomUtilities,
    WindowEvents,
    DomHelper,
    IdentHeight
};
