import { DomHelper } from '../general/utils';

export default class FormServices {
    constructor() {
        this.forms = document.getElementsByClassName('ondigoform-t3');

        if(this.forms.length) {
            for(let form of Array.from(this.forms)) {
                this.init(form);
            }
        }
    }

    init (form) {
        let _scope = this,
            serviceSelections = form.querySelectorAll('[data-js="offer"]');

        if(serviceSelections.length) {
            for(let serviceSelection of Array.from(serviceSelections)) {
                serviceSelection.onchange = function () {
                    let activeOption = this.options[this.selectedIndex],
                      classes = activeOption.getAttribute('data-show'),
                      classesArr = classes.split(',');

                    _scope.handleServiceOptions(form, classesArr);
                }
            }
        }
    }

    handleServiceOptions (form, classes) {
        let serviceOptions = form.getElementsByClassName('service-option');

        if(serviceOptions.length) {
            for(let option of Array.from(serviceOptions)) {
                option.classList.remove('visible');
                option.querySelectorAll('input, select')[0].value = '';
            }
        }

        for(let activeClass of Array.from(classes)) {
            let activeOption = form.querySelectorAll('.service-option.' + activeClass)[0];

            if(DomHelper.elementExists(activeOption)) {
                activeOption.classList.add('visible');
            }
        }
    }
}
