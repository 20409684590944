/*global $, jQuery, Modernizr, document, window, Ondigo */

(function ($) {
    'use strict';

    // Class Definition
    Ondigo.SliderLightbox = function (element) {
        var _instance = this;

        this.$window = $(window);
        this.$slider = $(element);
        this.$sliderOverflow = $('.sliderLightboxOverflow', this.$slider);
        this.$sliderLightboxPreviews = $('.sliderLightboxPreviews', this.$slider);
        this.$sliderLightboxPreviewsImages = $('img', this.$sliderLightboxPreviews);
        this.maxPages = Math.ceil(this.$sliderLightboxPreviewsImages.length / 4);
        this.$slidesWrapper = $('.sliderLightboxWrapper', this.$slider);
        this.$slides = $('.slide', this.$slider);
        this.$button = $('.sliderButton', this.$slider);
        this.$previews = $('.preview', this.$slider);
        this.descriptionMaxHeight = 0;

        this.currentPage = 0;
        this.slideCount = this.$slides.length;
        this.isAnimating = false;
        this.currentMargin = 0;
        this.slideAnimatedWithSwipe = false;
        this.swipingPoint = 75;
        this.activeBreakpoint = this.getActiveBreakPoint();
        this.imageResFactor = this.$slides.eq(0).find('img.'+this.activeBreakpoint).eq(0).data('resolution-factor');

        this.$nextButton = $('.next', this.$slider);
        this.$prevButton = $('.prev', this.$slider);

        this.$nextButtonThumbnails = $('.onIconArrowCircleRight', this.$slider);
        this.$prevButtonThumbnails = $('.onIconArrowCircleLeft', this.$slider);

        this.init = true;

        this.previewPage = 0;

        this.determineActiveBreakpoint();
        this.updateSliderDimensions();

        if (this.slideCount > 5) {
            this.$nextButtonThumbnails.show();
        }

        $('.imageWrapper', this.$slider).height('');

        this.$window.on('load', function () {
            _instance.preloadImage(false, false); // preload the next image
        });

        this.$window.on('resize', function () {
            _instance.$sliderLightboxPreviews.removeClass('animate');
            _instance.handlePreviews();
            window.setTimeout(function () {
                _instance.$sliderLightboxPreviews.addClass('animate');
            });
        });

        this.$button.on('click', function (e) {
            _instance.updateCurrentElement(e);
        });

        this.$prevButtonThumbnails.on('click', function (e) {
            if (_instance.previewPage !== 0) {
                _instance.previewPage--;
                _instance.$sliderLightboxPreviews.addClass('animate');
                _instance.handlePreviews();
                window.setTimeout(function () {
                    _instance.$sliderLightboxPreviews.removeClass('animate');
                }, 301);
            }
        });

        this.$nextButtonThumbnails.on('click', function (e) {
            if (_instance.previewPage !== _instance.maxPages - 1) {
                _instance.previewPage++;
                _instance.$sliderLightboxPreviews.addClass('animate');
                _instance.handlePreviews();
                window.setTimeout(function () {
                    _instance.$sliderLightboxPreviews.removeClass('animate');
                }, 301);
            }
        });

        this.handlePreviews();

        if (Ondigo.Utils.isIE()) {
            this.$previews.on('mouseover mouseout', function (e) {
                e.preventDefault();
                _instance.$sliderLightboxPreviews.css('text-indent', _instance.previewPage * (_instance.$sliderLightboxPreviews.width())*-1 -1 + 'px');
                _instance.$sliderLightboxPreviews.css('text-indent', _instance.previewPage * (_instance.$sliderLightboxPreviews.width())*-1 + 'px');
                return false;
            });
        }

        this.$previews.on('click', function (e) {
            _instance.$previews.removeClass('active');
            if (!$(e.target).hasClass('preview')) {
                _instance.currentPage = $(e.target).parents('.preview').index();
                $(e.target).parents('.preview').addClass('active');
            }
            else {
                _instance.currentPage = $(e.target).index();
                $(e.target).addClass('active');
            }

            _instance.currentMargin = -_instance.$slider.width() * _instance.currentPage;

            _instance.preloadImage(true, false);
            _instance.updateActiveSlide();
            _instance.animateSlide();


            _instance.$slides.removeClass('active');
            $(_instance.$slides[_instance.currentPage]).addClass('active');
        });

        if (Ondigo.Utils.hasTouchscreen()) {
            this.$window.on('orientationchange', function () {
                setTimeout(function () {
                    _instance.determineActiveBreakpoint();
                    _instance.updateSliderDimensions();
                }, 100);
            });

            this.$sliderOverflow.swipe({
                swipeStatus: $.proxy(_instance.swipeSlide, this),
                threshold: 10,
                maxTimeThreshold: 2500,
                fingers: 'all',
                preventDefaultEvents: false,
                allowPageScroll: 'vertical',
                tap:function(event, target) {
                    if($(target).hasClass('video') || $(target).children().hasClass('video')) {
                        $(target).trigger('openLightbox');
                    }
                }
            });
        }
        else {
            this.$window.on('resize', function () {
                setTimeout(function () {
                    _instance.determineActiveBreakpoint();
                    _instance.updateSliderDimensions();
                }, 100);
            });
        }

        // once again for ie to be completely sure that even ie gets it
        if (Ondigo.Utils.isOutdatedIE() || Ondigo.Utils.isIE10()) {
            setTimeout(function () {
                _instance.updateSliderDimensions();
            }, 2000);
        }

        _instance.fadeInNavigationalElements();

        this.$previews.eq(0).addClass('active');

    };

    Ondigo.SliderLightbox.prototype = {
        handlePreviews: function () {
            this.$sliderLightboxPreviews.css('text-indent', this.previewPage * (this.$sliderLightboxPreviews.width())*-1 + 'px');

            $('.preview', this.$sliderLightboxPreviews).removeClass('noClicks');

            for (var i = 0; i < this.previewPage*4; i++) {
                $('.preview', this.$sliderLightboxPreviews).eq(i).addClass('noClicks');
            }

            if (this.previewPage === 0) {
                this.$prevButtonThumbnails.removeClass('active');
            }
            else {
                this.$prevButtonThumbnails.addClass('active');
            }
            if (this.previewPage === this.maxPages - 1) {
                this.$nextButtonThumbnails.removeClass('active');
            }
            else {
                this.$nextButtonThumbnails.addClass('active');
            }
        },
        determineActiveBreakpoint: function () {
            var activeBreakpoint,
                $imageToBeLoaded;

            activeBreakpoint = this.getActiveBreakPoint();
            $imageToBeLoaded = $(this.$slides[this.currentPage]).find('img.' + activeBreakpoint);

            if (this.activeBreakpoint !== activeBreakpoint || $imageToBeLoaded.attr('src') === '' || parseInt($imageToBeLoaded.attr('autoload'), 10) === 0) {

                this.activeBreakpoint = activeBreakpoint;

                if (this.init) {
                    this.preloadImage(true, false);
                    this.preloadImage(false, false);

                    this.init = false;
                } else {
                    this.preloadImage(true, false);
                }
            }
        },
        /**
         *
         * @param loadNow (if true load the $activeImage)
         * @param loadPreviousImage (if true load the image previous to $activeImage)
         */
        preloadImage: function (loadNow, loadPreviousImage) {
            var $imageToBeLoaded,
                imageToBeLoadedSource,
                _instance = this;

            if (loadNow) {
                // dot-nav used, need to load current image
                $imageToBeLoaded = $(this.$slides[this.currentPage]).find('img.' + this.activeBreakpoint);
            } else if (loadPreviousImage) {
                $imageToBeLoaded = $(this.$slides[this.currentPage - 1]).find('img.' + this.activeBreakpoint);
            } else {
                $imageToBeLoaded = $(this.$slides[this.currentPage + 1]).find('img.' + this.activeBreakpoint);
            }

            if (($imageToBeLoaded.length && $imageToBeLoaded.attr('src') === '') || parseInt($imageToBeLoaded.attr('autoload'), 10) === 0) {
                if (window.devicePixelRatio > 1) {
                    imageToBeLoadedSource = $imageToBeLoaded.data('retina');
                } else {
                    imageToBeLoadedSource = $imageToBeLoaded.data('image-source');
                }
                if (loadNow) {
                    $imageToBeLoaded[0].onload = function () {
                        _instance.updateSliderDimensions();
                    };
                }

                if($imageToBeLoaded.attr('src') === '' || $imageToBeLoaded.attr('src') !== imageToBeLoadedSource) {
                    var $image = $('<img src="' + imageToBeLoadedSource + '">'),
                        width = $imageToBeLoaded.attr('width') > Ondigo.Utils.getWindowInnerWidth() ? Ondigo.Utils.getWindowInnerWidth() : $imageToBeLoaded.attr('width');
                    $imageToBeLoaded.addClass('loading').css('height', (width / $imageToBeLoaded.data('resolution-factor')) + 'px').css('width', width);
                    $image.on('load', function () {
                        $imageToBeLoaded.removeAttr('autoload')
                            .removeAttr('height')
                            .removeClass('loading')
                            .attr('style', '')
                            .hide()
                            .attr('src', imageToBeLoadedSource)
                            .fadeIn('fast', function() {
                                $imageToBeLoaded.removeAttr('style');
                            });
                    });
                }
            }
        },
        fadeInNavigationalElements: function () {
            this.$nextButton.fadeIn(0);
        },
        swipeSlide: function (event, phase, direction, distance) {
            if (this.currentPage < this.slideCount - 1 && direction === 'left' && this.slideAnimatedWithSwipe === false) {

                this.$slidesWrapper.css({
                    'margin-left': this.currentMargin - distance
                });

                if (distance > this.swipingPoint) {

                    this.slideAnimatedWithSwipe = true;

                    this.currentPage++;

                    this.updateActiveSlide();
                    this.animateSlide();
                }
            } else if (this.currentPage > 0 && this.slideCount && direction === 'right' && this.slideAnimatedWithSwipe === false) {

                this.$slidesWrapper.css({
                    'margin-left': this.currentMargin + distance
                });

                if (distance > this.swipingPoint) {

                    this.slideAnimatedWithSwipe = true;

                    this.currentPage--;

                    this.updateActiveSlide();
                    this.animateSlide();
                }
            }

            if (phase === 'end') {
                this.slideAnimatedWithSwipe = false;
                this.animateSlide();
                this.previewPage = Math.ceil((this.currentPage+1) / 4) - 1;
                this.handlePreviews();
                this.$previews.removeClass('active');
                this.$previews.eq(this.currentPage).addClass('active');
                this.$slides.removeClass('active');
                $(this.$slides[this.currentPage]).addClass('active');
            }
        },
        updateSliderDimensions: function () {
            this.currentMargin = -this.$slider.width() * this.currentPage;
            this.imageResFactor = this.$slides.eq(0).find('img.' + this.activeBreakpoint).eq(0).data('resolution-factor');

            var imageMaxHeight = this.$slider.width() / this.imageResFactor,
                _instance = this,
                finalheight,
                textBoxPresent = $('.contentRow', this.$slides).length > 0,
                padding = 20;

            this.descriptionMaxHeight = 0;


            if(textBoxPresent) {
                $.each(this.$slides, function () {
                    var $this = $(this),
                        $slideDescription = $('.contentRow .content', $this),
                        h = $slideDescription.innerHeight() + padding;
                    _instance.descriptionMaxHeight = Math.max(h, _instance.descriptionMaxHeight);
                });
            }


            if (Ondigo.Utils.getWindowInnerWidth() < Ondigo.breakpointWide) {
                finalheight = this.descriptionMaxHeight + imageMaxHeight;
            }
            else {
                finalheight = imageMaxHeight;
            }

            if (finalheight === 0) {
                return;
            }

            this.$sliderOverflow.css({
                'height': finalheight + 'px'
            });

            this.$slides.width(this.$slider.width());

            this.$slidesWrapper.css({
                'margin-left': this.currentMargin,
                'width': this.$slider.width() * this.slideCount
            });

            this.$slides.height('auto');
            this.$slides.css({'height': finalheight});

        },
        updateCurrentElement: function (e) {
            if (!this.isAnimating) {
                var _instance = this,
                    $currentImage,
                    imageToBeLoadedSource;
                this.isAnimating = true;

                if ($(e.target).hasClass('prev')) {
                    this.currentPage--;

                    this.preloadImage(true, false); // load the previous image
                    this.preloadImage(false, true); // load the image before that one
                } else {
                    this.currentPage++;
                }

                this.previewPage = Math.ceil((this.currentPage+1) / 4) - 1;
                this.handlePreviews();

                $currentImage = $(this.$slides[this.currentPage]).find('img.' + this.activeBreakpoint);

                this.$slides.removeClass('active');
                $(this.$slides[this.currentPage]).addClass('active');

                this.$previews.removeClass('active');
                this.$previews.eq(this.currentPage).addClass('active');

                if ($currentImage.attr('src') === '') {
                    $currentImage[0].onload = function () {
                        _instance.updateSliderDimensions();
                    };

                    if (window.devicePixelRatio > 1) {
                        imageToBeLoadedSource = $currentImage.data('retina');
                    } else {
                        imageToBeLoadedSource = $currentImage.data('image-source');
                    }
                    $currentImage.attr('src', imageToBeLoadedSource);
                }

                this.updateActiveSlide();
                this.animateSlide();
            }
        },

        getActiveBreakPoint : function () {
            var state = 'desktop';

            if (Ondigo.Utils.getWindowInnerWidth() < Ondigo.breakpointWide) {
                state = 'smartphone';
            } else if (Ondigo.Utils.getWindowInnerWidth() >= Ondigo.breakpointWide && Ondigo.Utils.getWindowInnerWidth() < Ondigo.breakpointMax) {
                state = 'tablet';
            }

            return state;
        },

        updateActiveSlide: function () {
            this.init = false; //slide needs to be updated, which means we survived the init-phase

            if (this.currentPage === this.slideCount - 1) {
                this.$nextButton.hide();
                this.$prevButton.show();
            } else if (this.currentPage < 1) {
                this.$prevButton.hide();
                this.$nextButton.show();
            } else {
                this.$button.show();
            }

            this.currentMargin = -this.$slider.width() * this.currentPage;

            this.preloadImage(true, false);
        },
        animateSlide: function () {
            var _instance = this;

            this.$slidesWrapper.animate({
                'margin-left': this.currentMargin
            }, 300, function () {
                _instance.isAnimating = false; //allow another animation
            });
        },
        switchImageAndSlider : function () {
            if (Ondigo.Utils.getWindowInnerWidth() < Ondigo.breakpointWide) {
                this.$sliderOverflow.removeClass('hidden');
            } else {
                this.$sliderOverflow.addClass('hidden');
            }
        }
    };

    // plugin definition
    $.fn.initSliderLightbox = function () {
        return new Ondigo.SliderLightbox(this);
    };
    $.fn.initSliderLightbox.Constructor = Ondigo.SliderLightbox;

}(window.jQuery));


$(document).ready(function () {
    'use strict';

    var $slider = $('.sliderLightbox');
    if ($slider.length) {
        $.each($slider, function () {
            $(this).initSliderLightbox();
        });
    }
});