import datepicker from 'js-datepicker';

export default class Datepicker {
    constructor () {
        let _scope = this;
        this.datepickers = document.getElementsByClassName('datepicker');

        if(this.datepickers.length) {
            for(let datepicker of Array.from(this.datepickers)) {
                _scope.init(datepicker);
            }
        }
    }

    init(elem) {
        let minDate = '',
            maxDate = '';

        if(elem.classList.contains('past')) {
            maxDate = new Date();
        } else if(elem.classList.contains('future')) {
            minDate = new Date();
        }

        datepicker(elem.querySelectorAll('input')[0], {
            startDay: 1,
            customDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            customMonths: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            formatter: (input, date, instance) => {
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
                    month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1),
                    year = date.getFullYear();

                input.value = day + '.' + month + '.' + year;
            },
            minDate: minDate,
            maxDate: maxDate,
            showAllDates: true,
            disableYearOverlay: true
        })
    }
}


