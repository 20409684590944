/*global $, jQuery, Modernizr, document, window, Ondigo */

(function ($) {
    'use strict';

    // Class Definition
    Ondigo.ThreeColumnImageContent = function (element) {
        this.$threeColumnImageContent = $(element);

        this.$headlines = $('h3', this.$threeColumnImageContent);
        this.$headlineText = $('h3 .verticalBottom', this.$threeColumnImageContent);
        this.$maxHeadlineHeight = 0;

        this.init();
    };

    Ondigo.ThreeColumnImageContent.prototype = {
        init: function () {
            var _scope = this;

            this.handleHeadlineHeight();

            $(window).on('resize', function () {
                _scope.handleHeadlineHeight();
            });
        },
        handleHeadlineHeight: function () {
            var _scope = this;

            this.$maxHeadlineHeight = 0;
            this.$headlines.removeAttr('style');

            if (Ondigo.Utils.getWindowInnerWidth() > 999) {
                $.each(this.$headlineText, function () {
                    if ($(this).height() > _scope.$maxHeadlineHeight) {
                        _scope.$maxHeadlineHeight = $(this).height();
                    }
                });
                this.$headlines.height(this.$maxHeadlineHeight);
            }
        }
    };

    // plugin definition
    $.fn.threeColumnImageContent = function () {
        return new Ondigo.ThreeColumnImageContent(this);
    };
    $.fn.threeColumnImageContent.Constructor = Ondigo.ThreeColumnImageContent;

}(window.jQuery));


$(document).ready(function () {
    'use strict';

    var $slider = $('.threeColumnImageContent');
    if ($slider.length) {

        $.each($slider, function () {
            $(this).threeColumnImageContent();
        });
    }
});