import {DomUtilities} from '../general/utils';

class SendForm {

    static send (form,submitButtonText, container, errormessage, e) {
        let url = form.getAttribute('action'),
            buttons = form.getElementsByTagName('button'),
            formData = new FormData(form);

        for(let button of Array.from(buttons)) {
            if(button.parentNode.classList.contains('submit')) {
                formData.append(button.name, button.value);
            }
        }

        if(form.classList.contains('multistep')) {
            formData.append('tx_form_formframework[' + form.getAttribute('id') + '][__currentPage]', 1);
        }

        let fetchOptions = {
            method: 'POST',
            body: formData,
            credentials: 'include'
        };

        form.classList.add('form-disabled');
        let errorContainer = document.getElementById('error-captcha');
        errorContainer.classList.remove('hidden');

        let responsePromise = fetch(url, fetchOptions);

        responsePromise.then(function (response) {
            if(response.ok) {
                return response.json();
            } else {
                return false;
            }
        }).then(function (jsonData) {
          if(jsonData !== false && jsonData.capture) {

            form.classList.remove('form-disabled');
            let capEmpty = document.getElementsByClassName('captureEmpty')[0];
            let capError = document.getElementsByClassName('captureError')[0];

            let submitButton = form.querySelectorAll('button[type=submit]')[0];
            submitButton.innerHTML = submitButtonText;

            DomUtilities.closest(capError, '.form-group').classList.add('has-error');
            capEmpty.classList.add('hidden');
            capError.classList.remove('hidden');
            form.querySelectorAll('button[type=submit]')[0].removeAttribute('disabled');
            setTimeout(function() {
              errorContainer.classList.add('hidden');
            },2000);
            return;
          }

          form.classList.add('hidden');
            if(jsonData !== false) {
                SendForm.pageViewTracking(form);
                SendForm.addContentToFormFinish(form, container, jsonData.content);
            } else {
                if(errormessage !== null && errormessage !== undefined && errormessage !== 'undefined') {
                    SendForm.addContentToFormFinish(form, container, errormessage);
                } else {
                    SendForm.addContentToFormFinish(form, container, '<h3>Oops, an error occurred!</h3>');
                }
            }
        }).catch(function (e) {
            console.warn('Formular Error: ' + e);

            if (errormessage !== null && errormessage !== undefined && errormessage !== 'undefined') {
                SendForm.addContentToFormFinish(form, container, errormessage);
            } else {
                SendForm.addContentToFormFinish(form, container, '<div class="form-finish error"><h3>Oops, an error occurred!</h3></div>');
            }
        });

        // needed for edge and ie...
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    static addContentToFormFinish (form, container, content) {
        let headerHeight = document.getElementsByTagName('header').length && document.body.classList.contains('stickyHeader') ? document.getElementsByTagName('header')[0].offsetHeight : 0;

        if (container !== null) {
            DomUtilities.scrollToY((window.pageYOffset + container.getBoundingClientRect().top - headerHeight), 300);

            container.innerHTML = content;
        } else {
            DomUtilities.scrollToY((window.pageYOffset + form.parentNode.getBoundingClientRect().top - headerHeight), 300);

            form.parentNode.insertAdjacentHTML('afterBegin', content);
            form.parentNode.removeChild(form);
        }
    }

    static pageViewTracking (form) {
        let action = '',
            id = form.getAttribute('id');

        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }

        // https://www.fse-gruppe.com/kontakt/
        if(id.indexOf('contact-fse-group') > -1) {
            action = '/gruppecontactsend';
            gtag('event', 'conversion', {'send_to': 'AW-10823095976/86dJCI_gmckDEKit7ago'});
        }

        // https://www.fse-pflege.de/kontakt/
        if(id.indexOf('contact-fse-care') > -1) {
            action = '/pflegecontactsend';
            gtag('event', 'conversion', {'send_to': 'AW-10823095976/86dJCI_gmckDEKit7ago'});
        }

        // https://www.fse-pflege.de/pflegeplatzanfrage/
        if(id.indexOf('space-request-fse-care') > -1) {
            action = '/pflegeplatzanfragesend';
        }

        // https://www.fse-pflege.de/pflegefachkraft/
        if(id.indexOf('online-application-fse-care') > -1) {
            action = '/pflegefachkraftsend';
            gtag('event', 'conversion', {'send_to': 'AW-10823095976/zm4ICIOO8MUDEKit7ago'});
        }

        // https://www.fse-pflege.de/pflegeassistenz/
        if(id.indexOf('online-application-fse-care-assistent') > -1) {
            action = '/pflegeassistenzsend';
            gtag('event', 'conversion', {'send_to': 'AW-10823095976/zm4ICIOO8MUDEKit7ago'});
        }

        // https://www.fse-gruppe.com/fse-catering/
        if(id.indexOf('contact-overlay') > -1) {
            action = '/cateringangebotsend';
        }

        // https://www.fse-gruppe.com/fse-facility/kontakt/
        if(id.indexOf('contact-fse-facility') > -1) {
            action = '/facilitycontactsend';
            gtag('event', 'conversion', {'send_to': 'AW-10823095976/86dJCI_gmckDEKit7ago'});
        }

        // https://www.fse-gruppe.com/fse-facility/angebot-anfordern/
        if(id.indexOf('offer-request-fse-facility') > -1) {
            action = '/facilityangebotsend';
        }

        // https://www.fse-gruppe.com/fse-fortbildung/kontakt/
        if(id.indexOf('contact-fse-education') > -1) {
            action = '/fortbildungcontactsend';
            gtag('event', 'conversion', {'send_to': 'AW-10823095976/86dJCI_gmckDEKit7ago'});
        }

        // https://www.fse-gruppe.com/jobs/jetzt-bewerben/
        if(id.indexOf('online-application-fse-career') > -1) {
            action = '/gruppebewerbungsend';
            gtag('event', 'conversion', {'send_to': 'AW-10823095976/zm4ICIOO8MUDEKit7ago'});
        }

        // https://www.fse-gruppe.com/jobs/karriere/
        if(id.indexOf('online-application-fse-career-internship') > -1) {
            action = '/gruppebewerbungpraktikumsend';
        }

        // https://www.fse-gruppe.com/jobs/ausbildung/
        if(id.indexOf('online-application-fse-career-training') > -1) {
            action = '/gruppebewerbungausbildungsend';
        }

        let _paq = window._paq || [];
        window._paq = _paq;

        _paq.push(['setCustomUrl', window.location.origin + action]);
        _paq.push(['setDocumentTitle', document.title]);
        _paq.push(['trackPageView']);
    }
}

export {
    SendForm
};
