import { TouchSwipe } from "../libs/touchSwipeDetection"

export default class Philosophy {
    constructor () {
        this.philosophywrapper = document.getElementsByClassName('philosophy-wrapper');

        this.slideCount = 0;
        this.navigationInitialized = false;
        this.isSliding = false;

        this.touchInitialized = false;

        if(this.philosophywrapper.length) {
            for(let philosophywrapper of Array.from(this.philosophywrapper)) {
                this.slideCount = philosophywrapper.getElementsByClassName('philosophy-item').length;
                this.init(philosophywrapper);
            }
        }
    }

    init (wrapper) {
        Philosophy.setItemHeight(wrapper);

        if(Ondigo.Utils.getWindowInnerWidth() >= 768) {
            // 5 elements min needed
            wrapper.getElementsByClassName('philosophy-items-container')[0].prepend(wrapper.getElementsByClassName('philosophy-item')[this.slideCount - 1]);
            wrapper.getElementsByClassName('philosophy-items-container')[0].prepend(wrapper.getElementsByClassName('philosophy-item')[this.slideCount - 1]);
        }

        this.initNavigation(wrapper);
        this.handleTouchSwipe(wrapper);
        this.handleResize(wrapper);
    }

    handleResize (wrapper) {
        let _scope = this;
        window.addEventListener('resize', function () {
            setTimeout(function () {
                Philosophy.setItemHeight(wrapper);
                _scope.handleTouchSwipe(wrapper);
            }, 100);
        });

        window.addEventListener('orientationchange', function () {
            setTimeout(function () {
                Philosophy.setItemHeight(wrapper);
                _scope.handleTouchSwipe(wrapper);
            }, 100);
        });
    }

    initNavigation (wrapper) {
        let _scope = this;

        if(!this.navigationInitialized) {
            wrapper.getElementsByClassName('onIconArrowCircleLeft')[0].addEventListener('click', function () {
                _scope.slideCarousel(wrapper, _scope.slideCount - 1, 'left');
            });

            wrapper.getElementsByClassName('onIconArrowCircleRight')[0].addEventListener('click', function () {
                _scope.slideCarousel(wrapper, 0, 'right');
            });

            this.navigationInitialized = true;
        }
    }

    slideCarousel (wrapper, index, direction) {
        let _scope = this;

        if(!this.isSliding && Ondigo.Utils.getWindowInnerWidth() >= 768) {
            this.isSliding = true;

            if(index === 0) {
                wrapper.getElementsByClassName('philosophy-items-container')[0].append(wrapper.getElementsByClassName('philosophy-item')[index]);
            } else {
                wrapper.getElementsByClassName('philosophy-items-container')[0].prepend(wrapper.getElementsByClassName('philosophy-item')[index]);
            }

            Philosophy.removeClasses(wrapper);
            Philosophy.setClasses(wrapper, direction);

            setTimeout(function () {
                _scope.isSliding = false;
            }, 700);
        }
    }

    static setClasses (wrapper, direction) {
        //prevent overlap while image slide
        if(direction === 'left') {
            wrapper.getElementsByClassName('philosophy-item')[1].style.zIndex = '2';
            wrapper.getElementsByClassName('philosophy-item')[3].style.zIndex = '2';
        }
        if(direction === 'right') {
            wrapper.getElementsByClassName('philosophy-item')[1].style.zIndex = '2';
            wrapper.getElementsByClassName('philosophy-item')[3].style.zIndex = '2';
        }

        wrapper.getElementsByClassName('philosophy-item')[1].classList.add('prev');
        wrapper.getElementsByClassName('philosophy-item')[2].classList.add('active');
        wrapper.getElementsByClassName('philosophy-item')[3].classList.add('next');
    }

    static removeClasses (wrapper) {
        for(let item of Array.from(wrapper.getElementsByClassName('philosophy-item'))) {
            item.style.zIndex = '';
            item.classList.remove('prev');
            item.classList.remove('active');
            item.classList.remove('next');
        }
    }

    static setItemHeight (wrapper) {
        let maxHeight,
            items = wrapper.getElementsByClassName('philosophy-item');

        maxHeight = 0;

        if(Ondigo.Utils.getWindowInnerWidth() < 768) {
            for(let i = 0; i < items.length; i++) {
                let item = items[i],
                    h;

                item.style.height = '';

                h = item.clientHeight;
                maxHeight = Math.max(h, maxHeight);
            }

            for(let i = 0; i < items.length; i++) {
                let item = items[i];

                item.style.height = parseInt(maxHeight, 10) + 'px';
            }
        } else {
            for(let i = 0; i < items.length; i++) {
                let item = items[i];
                item.style.height = '';
            }
        }
    }

    handleTouchSwipe (wrapper) {
        let _scope = this,
            container = wrapper.getElementsByClassName('philosophy-items-container')[0];

        if(Ondigo.Utils.getWindowInnerWidth() >= 768 && !this.touchInitialized) {

            TouchSwipe.swipedetect(container, function (direction) {

                if (direction === 'left') {
                    _scope.slideCarousel(wrapper, 0, 'right');
                }

                if (direction === 'right') {
                    _scope.slideCarousel(wrapper, _scope.slideCount - 1, 'left');
                }
            });

            this.touchInitialized = true;
        } else {
            // unbind swipe events mobile and use native scroll

            container.ontouchstart = function() {
                return true;
            };
            container.ontouchmove = function() {
                return true;
            };
            container.ontouchend = function() {
                return true;
            };

            this.touchInitialized = false;
        }
    }
}
