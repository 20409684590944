/*global $, jQuery, Modernizr, document, Ondigo */

(function ($) {
    'use strict';

    // class definition
    Ondigo.IeFontIconLinksEnhancement = function () {
        this.$fontIconLinks = $(':not(.noIconEnhancement) > a[class*="onIcon"]');

        this.init();
    };

    Ondigo.IeFontIconLinksEnhancement.prototype = {
        init: function () {
            $.each(this.$fontIconLinks, function () {
                var $currentElement = $(this),
                    icon = $currentElement.attr('class');
                $currentElement.removeClass(icon);

                if (icon.indexOf('onIconRight') !== -1) {
                    $currentElement.append('<span class="' + icon + '"></span>');
                } else {
                    $currentElement.prepend('<span class="' + icon + '"></span>');
                }
            });
        }
    };

    // plugin definition
    $.ieFontIconLinksEnhancement = function () {
        return new Ondigo.IeFontIconLinksEnhancement();
    };
    $.ieFontIconLinksEnhancement.Constructor = Ondigo.IeFontIconLinksEnhancement;

}(jQuery));


$(document).ready(function () {
    'use strict';

    // if ie10 or lower
    if ($('html.lt-ie10').length || navigator.userAgent.match(/MSIE 10/) || Ondigo.Utils.isIE11()) {
        $.ieFontIconLinksEnhancement();
    }
    if (Ondigo.Utils.isIE10()) {
        $('.partner picture').css('margin', '0 auto');
        $('.threeColumnImageContent .flexWrapper').css('text-align', 'center');
        $('.threeColumnImageContent .flexWrapper div').css('margin', '0 auto');
        $('.lightbox .imageWrapper').css('margin', '0 auto');
        $('.lightbox .imageWrapper').css('text-align', 'center');
    }
    if (Ondigo.Utils.isIE10() || Ondigo.Utils.isIE11()) {
        $('.button').css('padding', '11px 33px 12px');

        if (Ondigo.Utils.getWindowInnerWidth() > 768) {
            $('.lightbox .previous.button').css('left', 'calc(((100% - 960px) / 2) - 140px)');
        }
        $(window).on('resize', function () {
            if (Ondigo.Utils.getWindowInnerWidth() > 768) {
                $('.lightbox .previous.button').css('left', 'calc(((100% - 960px) / 2) - 140px)');
            }
            else {
                $('.lightbox .previous.button').removeAttr('style');
            }
        });
    }
});
