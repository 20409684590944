/*global $, jQuery, Modernizr, document, Ondigo */

(function ($) {
    'use strict';

    // class definition
    Ondigo.ClickableBox = function (element) {
        var _instance = this;

        this.$element = $(element);

        this.isModernBrowser = !$('html.lt-ie10').length;
        this.link = null;

        // Case (1): new-window links. These need to be prepared onLoad, to make the links
        // instantly available. Sadly only in non ie-browser!
        if (this.isModernBrowser) {
            this.prepareExternalLinks();
        }

        // Case (2): same-window links. These can be handled via event delegation right onClick.
        this.$element.on('click', function (e) {
            if (!$(e.target).hasClass('ignoreClickableBox')) {
                _instance.redirect(e);
            }
        });
    };

    Ondigo.ClickableBox.prototype = {
        prepareExternalLinks: function () {
            var $clickableElementLink = $('a:eq(0)', this.$element),
                $currentClickableElementLink,
                i;

            for (i = 0; i < $clickableElementLink.length; i++) {
                $currentClickableElementLink = $($clickableElementLink[i]);

                if ($currentClickableElementLink.attr('target') !== undefined && $currentClickableElementLink.attr('target') !== '_self') {
                    $currentClickableElementLink.closest('.clickableBox').
                        addClass('hasJsLinkExternal').
                        append('<a class="clickableBoxExternalLink" href="' + $currentClickableElementLink.attr('href') + '" target="' + $currentClickableElementLink.attr('target') + '"></a>');
                }
            }
        },
        redirect: function (e) {

            var $currentElement = $(e.target),
                $linkElement = $currentElement.find('a:eq(0)'),
                linkToGoTo = $linkElement.attr('href'),
                slashFreeLink;

            if (!$currentElement.hasClass('clickableBox')) {

                $currentElement = $currentElement.parents('.clickableBox');
                $linkElement = $currentElement.find('a:eq(0)');
                linkToGoTo = $linkElement.attr('href');
            }

            if (linkToGoTo === undefined || $currentElement.hasClass('hasJsLinkExternal')) {
                return true;
            }

            // always open links in the same window regardless of target
            if (this.isModernBrowser && $linkElement.attr('target') !== undefined && $linkElement.attr('target') !== '_self') {
                return true;
            }

            if (linkToGoTo.split(':')[0] === 'http' || linkToGoTo.split(':')[0] === 'https') {
                window.location.href = linkToGoTo;
            }
            else {
                if (linkToGoTo.indexOf('mailto:') >= 0) {
                    window.location.href = linkToGoTo;
                }
                else {
                    slashFreeLink = linkToGoTo.charAt(0) === '/' ? linkToGoTo.substring(1) : linkToGoTo;
                    window.location.href = 'http://' + window.location.host + '/' + slashFreeLink;
                }
            }

            e.preventDefault();

        }
    };

    // plugin definition
    $.fn.clickableBox = function () {
        return new Ondigo.ClickableBox(this);
    };
    $.fn.clickableBox.Constructor = Ondigo.ClickableBox;

}(window.jQuery));

$(document).ready(function () {
    'use strict';

    if ($('.clickableBox').length) {
        $('.clickableBox').clickableBox();
    }
});