/*global $, jQuery, Modernizr, document, Ondigo */

// TODO: Facebook URL's MÜSSEN immer mit der Facebook ID angegeben sein. Schema: https://www.facebook.com/pages/USER/FACEBOOK-ID

(function ($) {
    'use strict';

    if (!Ondigo.mobileEnhancement) {
        Ondigo.mobileEnhancement = {};
    }

    Ondigo.mobileEnhancement = Ondigo.mobileEnhancement || {};

    // class definition
    Ondigo.mobileEnhancement.MobileEnhancement = function () {
        //Social Networks
        this.facebook = 'facebook';
        this.twitter = 'twitter';

        //Apps
        this.facebookapp = 'fb://profile/';
        this.twitterapp = 'twitter://user?screen_name=';
        this.googlemapsapp = 'comgooglemaps://?q=';
        this.applemapsapp = '//maps.apple.com/?q=';

        //Desktop URLS
        this.googlemaps = '//maps.google.com/maps?q=';
        this.googlemapsroute = '//maps.google.com/maps?daddr=';

        //Attributes
        this.apphref = 'data-app-href';
        this.dataroute = 'route';
        this.init();
    };

    Ondigo.mobileEnhancement.MobileEnhancement.prototype = {
        init: function () {
            $('body').addClass('activatedCssAnimations');
            this.formatAddresses();
            this.watchAddresses();
            this.watchLinks();
        },
        formatAddresses: function () {
            var _instance = this,
                link,
                data,
                start =  Ondigo.Utils.isIOSDevice() ? 'Current%20Location' : '',
                isAndroid = Ondigo.Utils.isAndroidDevice(),
                googleLink;

            //smartphoneversion
            if (Ondigo.Utils.isSmartphone()) {

                //add link to addresses
                $('.mapLink').each(function () {
                    var $this = $(this);

                    data = _instance.getDataLink($this);

                    if ($this.attr('data-attribute') === _instance.dataroute && !isAndroid) {
                        link = '<a href="#" target="_blank" class="addressLink isRoute" data="saddr=' + start + '&amp;daddr=' + encodeURIComponent(data) + '">' + $this.text() + '</a>';
                    } else if (isAndroid) {
                        googleLink  = $this.attr('data-attribute') === _instance.dataroute ? _instance.googlemapsroute : _instance.googlemaps;
                        link = '<a href="' + googleLink + encodeURIComponent(data) + '" target="_blank">' + $this.text() + '</a>';
                    } else {
                        link = '<a href="#" target="_blank" class="addressLink" data="' + encodeURIComponent(data) + '">' + $this.text() + '</a>';
                    }
                    $this.html(link);
                });
                //check page for app links like google maps or facebook
                this.checkAppLinks();
            } else {
                //desktopversion
                //delete links from phonenumbers
                $('a[href*="tel:"]').each(function () {
                    $(this).contents().unwrap();
                });
                //delete links from smsnumbers
                $('a[href*="sms:"]').each(function () {
                    $(this).contents().unwrap();
                });
                //add link to addresses
                $('.mapLink').each(function () {
                    var $this = $(this);

                    data = _instance.getDataLink($this);
                    googleLink = $this.attr('data-attribute') === _instance.dataroute ? _instance.googlemapsroute : _instance.googlemaps;
                    link = '<a href="' + googleLink + encodeURIComponent(data) + '" target="_blank">' + $this.text() + '</a>';
                    $this.html(link);
                });
            }
        },
        getDataLink: function ($obj) {
            var attr = $obj.attr('data-link'), data;

            if (attr !== undefined && attr !== false) {
                data = $obj.attr('data-link');
            } else {
                data = $obj.text();
            }

            return data;
        },
        watchAddresses: function () {
            var _instance = this,
                link;
            //Click for all addresses
            $('.addressLink').click(function (e) {
                e.preventDefault();
                var $this = $(this);
                //getting address
                link = $this.attr('data');

                _instance.startApp(_instance.googlemapsapp + link, _instance.applemapsapp + link);
            });
        },
        watchLinks: function () {
            var _instance = this;
            //click on links to pages which maybe have an app on the device installed
            $('a').click(function (e) {
                var $this = $(this);

                //if link maybe has an app for that
                if ($this.attr(_instance.apphref)) {
                    e.preventDefault();
                    //start the app if it exists, otherwise use the default desktop link
                    _instance.startApp($this.attr(_instance.apphref), $this.attr('href'));
                }
            });
        },
        //checks page for social links
        checkAppLinks: function () {
            var _instance = this;
            $('a[href*="facebook."]').each(function () {
                _instance.setAppLink($(this), _instance.facebook);
            });
            $('a[href*="twitter."]').each(function () {
                _instance.setAppLink($(this), _instance.twitter);
            });
        },
        setAppLink: function (linkscope, app) {
            //removing double slash from url
            var normalized = linkscope.attr('href').split('//').join(''),
                domainEndPos,
                param;
            //removing slash at end of link if exists
            if (normalized.lastIndexOf('/') === ((normalized.length) - 1)) {
                normalized = normalized.substr(0, normalized.lastIndexOf('/'));
            }
            //delete everything before the last slash to get the needed parameter
            domainEndPos = normalized.lastIndexOf('/');
            param = normalized.substr(domainEndPos + 1, normalized.length);

            if (app === this.facebook) {
                if (Ondigo.Utils.isNumber(param)) {
                    linkscope.attr(this.apphref, this.facebookapp + param);
                } else {
                    //To start the facebook app you need the id of the page instead of the name
                    this.getJSONResponse('//graph.facebook.com/' + param, this.generateFacebookLink, linkscope, this);
                }
            } else if (app === this.twitter) {
                linkscope.attr(this.apphref, this.twitterapp + param);
            }
        },
        startApp: function (applink, alternative) {
            var time = new Date().getTime();

            //Start app
            window.location = applink;
            window.setTimeout(function () {
                //App not started
                if ((new Date().getTime() - time) < 50) {
                    window.location = alternative;
                }
            }, 30);
        },
        getJSONResponse: function (url, callback, linkscope, scope) {
            $.getJSON(url, {format: 'json'}).done(
                function (data) {
                    callback(data, linkscope, scope);
                }
            );
        },
        generateFacebookLink: function (data, linkscope, scope) {
            linkscope.attr(scope.apphref, scope.facebookapp + data.id);
        }
    };

    // plugin definition
    $.fn.createMobileEnhancement = function () {
        return new Ondigo.mobileEnhancement.MobileEnhancement();
    };
    $.fn.createMobileEnhancement.Constructor = Ondigo.mobileEnhancement.MobileEnhancement;


}(window.jQuery));

$(document).ready(function () {
    'use strict';

    $.fn.createMobileEnhancement();
});