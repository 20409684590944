/**
 * Created by mkoehler on 01.02.19.
 */

import { TouchSwipe } from "../libs/touchSwipeDetection"

export default class Places {
    constructor () {
        let _scope = this;
        this.wrapper = document.querySelectorAll('.row.places');
        this.activeIndex = 0;

        if(this.wrapper.length) {
            for (let wrapper of Array.from(this.wrapper)) {
                _scope.init(wrapper);
            }
        }
    }

    init (wrapper) {
        let _scope = this,
            markers = wrapper.getElementsByClassName('marker'),
            placesWrapper = wrapper.getElementsByClassName('places-wrapper')[0],
            placesOverflow = wrapper.getElementsByClassName('places-overflow')[0];

        for(let i = 0; i < markers.length; i++) {
            let marker = markers[i];

            _scope.initPlaceCloser(wrapper, i);

            marker.addEventListener('click', function () {
                _scope.removeCurrentActivePlace(wrapper);
                _scope.activatePlace(wrapper, this);

                // handle mobile slider
                if((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 768) {
                    placesWrapper.style.transform = 'translateX(-' + parseInt((window.innerWidth * 0.65) + 14, 10) * i + 'px)';
                    _scope.activeIndex = i;
                } else {
                    placesWrapper.removeAttribute('style');
                }
            });
        }

        TouchSwipe.swipedetect(placesOverflow, function (direction) {

            if (direction === 'left') {

                if(_scope.activeIndex < markers.length - 1) {
                    _scope.activeIndex = _scope.activeIndex + 1;

                    _scope.removeCurrentActivePlace(wrapper);
                    _scope.activatePlace(wrapper, markers[_scope.activeIndex]);
                    placesWrapper.style.transform = 'translateX(-' + parseInt((window.innerWidth * 0.65) + 14, 10) * _scope.activeIndex + 'px)';
                }
            }

            if (direction === 'right') {

                if(_scope.activeIndex > 0) {
                    _scope.activeIndex = _scope.activeIndex - 1;

                    _scope.removeCurrentActivePlace(wrapper);
                    _scope.activatePlace(wrapper, markers[_scope.activeIndex]);
                    placesWrapper.style.transform = 'translateX(-' + parseInt((window.innerWidth * 0.65) + 14, 10) * _scope.activeIndex + 'px)';
                }
            }
        });
    }

    removeCurrentActivePlace (wrapper) {
        if(wrapper.querySelectorAll('.marker.active').length) {
            wrapper.querySelectorAll('.marker.active')[0].classList.remove('active');
            wrapper.querySelectorAll('.place.active')[0].classList.remove('active');
            wrapper.querySelectorAll('.dot.active')[0].classList.remove('active');
        }
    }

    initPlaceCloser (wrapper, index) {
        let _scope = this;
        wrapper.querySelectorAll('.place .onIconClose')[index].addEventListener('click', function () {
            _scope.removeCurrentActivePlace(wrapper);
        });
    }

    activatePlace (wrapper, clickedMarker) {
        let chosenPlace = clickedMarker.getAttribute('data-place');

        wrapper.querySelectorAll('.marker[data-place="' + chosenPlace + '"]')[0].classList.add('active');
        wrapper.querySelectorAll('.place[data-place="' + chosenPlace + '"]')[0].classList.add('active');
        wrapper.querySelectorAll('.dot[data-place="' + chosenPlace + '"]')[0].classList.add('active');
    }
}