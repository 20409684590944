/*global $, jQuery, Modernizr, document, Ondigo, window */


(function () {
    'use strict';

    Ondigo.Utils = function () {

        var identHeight,
            currentDeviceOrientation = '',
            createIdentHeight = function() {
                var $identHeight = $('.identHeight');

                if ($identHeight.length) {
                    identHeight = $identHeight.adjustBoxHeight();
                }
            },
            updateIdentHeight = function() {
                if (identHeight) {
                    identHeight.updateIdentHeight();
                }
            },
            removeIdentHeight = function() {
                if (identHeight) {
                    identHeight.removeIdentHeight();
                }
            },
            getWindowInnerWidth = function () {
                return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            },
            getWindowInnerHeight = function () {
                return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            },
            getParameterByName = function (name, url) {
                if (!url) url = window.location.href;
                name = name.replace(/[\[\]]/g, "\\$&");
                let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, " "));
            },
            checkCurrentDeviceOrientation = function () {

                if (window.orientation) {
                    switch (window.orientation) {
                        case 0:
                            currentDeviceOrientation = 'portrait';
                            break;
                        case -90:
                            currentDeviceOrientation = 'landscape';
                            break;
                        case 90:
                            currentDeviceOrientation = 'landscape';
                            break;
                        case 180:
                            currentDeviceOrientation = 'landscape';
                            break;
                    }
                }
                return currentDeviceOrientation;
            },

            maxValueInArray = function (array) {
                return Math.max.apply(Math, array);
            },

            minValueInArray = function (array) {
                return Math.min.apply(Math, array);
            },

            isSmartphone = function () {
                return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Opera Mobile|Kindle|Windows Phone|PSP|AvantGo|Atomic Web Browser|Blazer|Chrome Mobile|Dolphin|Dolfin|Doris|GO Browser|Jasmine|MicroB|Mobile Firefox|Mobile Safari|Mobile Silk|Motorola Internet Browser|NetFront|NineSky|Nokia Web Browser|Obigo|Openwave Mobile Browser|Palm Pre web browser|Polaris|PS Vita browser|Puffin|QQbrowser|SEMC Browser|Skyfire|Tear|TeaShark|UC Browser|uZard Web|wOSBrowser|Yandex.Browser mobile/i).test(navigator.userAgent);
            },

            isIOSDevice = function () {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },

            isAndroidDevice = function () {
                return navigator.userAgent.match(/Android/i);
            },

            hasSmartphoneSize = function () {
               return Ondigo.Utils.getWindowInnerWidth() < Ondigo.breakpointWide;
            },

            hasTabletSize = function () {
                return (Ondigo.Utils.getWindowInnerWidth() <= Ondigo.breakpointMax) && (Ondigo.Utils.getWindowInnerWidth() >= Ondigo.breakpointWide);
            },

            hasDesktopSize = function () {
                return Ondigo.Utils.getWindowInnerWidth() >= Ondigo.breakpointMax;
            },

            isGreaterThanSmartphone = function () {
                return Ondigo.Utils.getWindowInnerWidth() >= Ondigo.breakpointWide;
            },

            isSmallerThanDesktop = function () {
                return Ondigo.Utils.getWindowInnerWidth() < Ondigo.breakpointMax;
            },

            elementIsAddedToDom = function (selector) {
                var timer,
                    defferer = $.Deferred();

                timer = window.setInterval(function() {
                    if ($(selector).length > 0) {
                        clearInterval(timer);
                        defferer.resolve();
                    }
                }, 200);

                window.setTimeout(function() {
                    clearInterval(timer);
                    defferer.reject();
                }, 2100);

                return defferer.promise();
            },

            getInputFieldName = function ($this) {
                var field = $this.attr('name');
                return field.substr(field.indexOf("][") + 1).replace('[', '').replace(']', '');
            },

            isIE11 = function () {
                var ua = navigator.userAgent.toString().toLowerCase(),
                    match = /(trident)(?:.*rv:([\w.]+))?/.exec(ua) ||/(msie) ([\w.]+)/.exec(ua)||['',null,-1];
                return match[2] !== -1;
            },

            isNumber = function (n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            },

            isOutdatedIE = function () {
                return $('html.lt-ie10').length > 0;
            },

            isIE10 = function () {
                return navigator && navigator.appVersion && navigator.appVersion.indexOf('MSIE 10') !== -1;
            },

            isIE = function () {
                var ua = window.navigator.userAgent;

                var msie = ua.indexOf('MSIE ');
                if (msie > 0) {
                    // IE 10 or older => return version number
                    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
                }

                var trident = ua.indexOf('Trident/');
                if (trident > 0) {
                    // IE 11 => return version number
                    var rv = ua.indexOf('rv:');
                    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
                }

                var edge = ua.indexOf('Edge/');
                if (edge > 0) {
                    // Edge (IE 12+) => return version number
                    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
                }

                // other browser
                return false;
            },

            elementInViewport = function (el) {
                let top = el.offsetTop;
                let left = el.offsetLeft;
                let width = el.offsetWidth;
                let height = el.offsetHeight;

                while(el.offsetParent) {
                    el = el.offsetParent;
                    top += el.offsetTop;
                    left += el.offsetLeft;
                }

                return (
                    top < (window.pageYOffset + window.innerHeight) &&
                    left < (window.pageXOffset + window.innerWidth) &&
                    (top + height) > window.pageYOffset &&
                    (left + width) > window.pageXOffset
                );
            },

            hasTouchscreen = function () {
                return $('html.touchevents').length > 0;
            },

            getCurrentState = function () {
                if (getWindowInnerWidth() < Ondigo.breakpointWide) {
                    return Ondigo.TabletState;
                } else {
                    return Ondigo.DesktopState;
                }
            },

            getAbsoluteElementHeight = function (elem) {
                return parseInt(elem.offsetHeight, 10) +
                    parseInt(window.getComputedStyle(elem).getPropertyValue('margin-top'), 10) +
                    parseInt(window.getComputedStyle(elem).getPropertyValue('margin-bottom'), 10) +
                    parseInt(window.getComputedStyle(elem).getPropertyValue('padding-bottom'), 10);
            },

            setDynamicIframeHeightById = function (frameId) {
                var myEventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent',
                    myEventListener = window[myEventMethod],
                    myPostEventMessage = myEventMethod === 'attachEvent' ? 'onmessage' : 'message',
                    myResizeEventMessage = myEventMethod === 'attachEvent' ? 'onresize' : 'resize',
                    useOrientationChange = window.hasOwnProperty('ondeviceorientation') ? true : false,
                    theFrame = document.getElementById(frameId);

                myEventListener(myPostEventMessage, function (e) {
                    if (e.data === parseInt(e.data, 10)) {
                        theFrame.height = e.data + 'px';
                    }
                }, false);

                function callRemoteFrame() {
                    setTimeout(function () {
                        theFrame.contentWindow.postMessage('sizing', '*');
                    }, 100);
                }

                if (useOrientationChange) {
                    myEventListener('orientationchange', function () {
                        callRemoteFrame();
                    });
                } else {
                    myEventListener(myResizeEventMessage, function () {
                        callRemoteFrame();
                    });
                }

                callRemoteFrame();

            },

            setCookie = function (cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays*24*60*60*1000));
                var expires = "expires="+ d.toUTCString();
                document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
            },

            getCookie = function (cname) {
                var name = cname + "=";
                var ca = document.cookie.split(';');
                for(var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },

            getElementByAttribute = function (attribute) {
                var matchingElements = [];
                var allElements = document.getElementsByTagName('*');
                for (var i = 0, n = allElements.length; i < n; i++) {
                    if (allElements[i].getAttribute(attribute) !== null) {
                        matchingElements.push(allElements[i]);
                    }
                }
                return matchingElements;
            },

            fadeOut = function (element, ms) {
                var op = 1;
                var timer = setInterval(function () {
                    if (op <= 0.1){
                        clearInterval(timer);
                        element.style.display = 'none';
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op -= op * 0.1;
                }, ms);
            },

            loadCss = function ( href, before, media ){
                var ss = window.document.createElement( 'link' );
                var ref = before || window.document.getElementsByTagName( 'script' )[ 0 ];
                ss.rel = 'stylesheet';
                ss.href = href;
                ss.media = 'only x';
                ref.parentNode.insertBefore( ss, ref );
                setTimeout( function(){
                    ss.media = media || 'all';
                } );
            },

            loadScript = function (url, callback) {
                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                if (script.readyState) {
                    script.onreadystatechange = function () {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            if (callback && typeof callback === 'function') {
                                callback();
                            }
                        }
                    };
                } else {
                    script.onload = function () {
                        if (callback && typeof callback === 'function') {
                            callback();
                        }
                    };
                }
                script.src = url;
                (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
            },

            fadeIn = function (element, ms) {
                var op = 0.1;  // initial opacity
                element.style.display = 'block';
                var timer = setInterval(function () {
                    if (op >= 1){
                        clearInterval(timer);
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.1;
                    alert("here");
                }, ms);
            },

            videoPlaysInline = function () {
                return !navigator.userAgent.match(/(iPhone|iPod)/);
            };

        // reveal the public API
        return {
            checkCurrentDeviceOrientation: checkCurrentDeviceOrientation,
            createIdentHeight: createIdentHeight,
            elementIsAddedToDom: elementIsAddedToDom,
            fadeOut: fadeOut,
            fadeIn: fadeIn,
            getAbsoluteElementHeight: getAbsoluteElementHeight,
            getCookie: getCookie,
            getCurrentState : getCurrentState,
            getInputFieldName: getInputFieldName,
            getWindowInnerWidth: getWindowInnerWidth,
            getWindowInnerHeight: getWindowInnerHeight,
            getElementByAttribute: getElementByAttribute,
            hasSmartphoneSize: hasSmartphoneSize,
            hasTabletSize: hasTabletSize,
            hasDesktopSize: hasDesktopSize,
            hasTouchscreen : hasTouchscreen,
            isSmartphone: isSmartphone,
            isIOSDevice: isIOSDevice,
            isAndroidDevice: isAndroidDevice,
            isGreaterThanSmartphone: isGreaterThanSmartphone,
            isSmallerThanDesktop: isSmallerThanDesktop,
            isIE10 : isIE10,
            isIE11: isIE11,
            isIE: isIE,
            isNumber: isNumber,
            isOutdatedIE : isOutdatedIE,
            elementInViewport: elementInViewport,
            loadCss: loadCss,
            loadScript: loadScript,
            maxValueInArray: maxValueInArray,
            minValueInArray: minValueInArray,
            removeIdentHeight: removeIdentHeight,
            setDynamicIframeHeightById : setDynamicIframeHeightById,
            setCookie: setCookie,
            updateIdentHeight: updateIdentHeight,
            videoPlaysInline : videoPlaysInline,
            getParameterByName: getParameterByName
        };
    }();
}());