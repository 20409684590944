/**
 * Created by mkoehler on 15.05.19.
 */

export default class PageMinHeight {
    constructor () {
        this.header = document.getElementsByClassName('header')[0];
        this.navigation = document.getElementsByClassName('mainNavigationWrapper')[0];
        this.main = document.getElementsByClassName('main')[0];
        this.footer = document.getElementsByClassName('footer')[0];

        this.init();
        this.handleResize();
    }

    init() {
        let headerHeight = parseInt(this.header.clientHeight, 10),
            navHeight = !!this.navigation ? parseInt(this.navigation.clientHeight, 10) : 0,
            footerHeight = parseInt(this.footer.clientHeight, 10);

        this.main.style.minHeight = (Ondigo.Utils.getWindowInnerHeight() - headerHeight - navHeight - footerHeight) + 'px';
    }

    handleResize () {
        let _scope = this;

        if(Ondigo.Utils.hasTouchscreen()) {
            window.addEventListener('orientationchange', function () {
                setTimeout(function () {
                    _scope.init();
                }, 100);
            });
        } else {
            window.addEventListener('resize', function () {
                setTimeout(function () {
                    _scope.init();
                }, 100);
            });
        }
    }
}