/**
 * Created by mkoehler on 07.05.19.
 */

// https://github.com/ganlanyuan/tiny-slider
import { tns } from 'tiny-slider/src/tiny-slider.module';

export default class JobDetailLocation {
    constructor () {
        this.wrappers = document.getElementsByClassName('location-slider');

        if(this.wrappers.length) {
            for(let wrapper of Array.from(this.wrappers)) {
                JobDetailLocation.init(wrapper);
            }
        }
    }

    static init (wrapper) {
        tns({
            container: wrapper,
            items: 1,
            loop: true,
            touch: true,
            lazyload: false,
            controlsText: ['', '']
        });
    }
}
