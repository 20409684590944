/*global $, jQuery, Modernizr, document, Ondigo */

(function ($) {
    'use strict';

    // class definition
    Ondigo.StickyMobileNav = function () {
        this.$body = $('body');
        this.init();
    };

    Ondigo.StickyMobileNav.prototype = {
        init: function () {
            var _scope = this;

            $(document).on('scroll resize orientationchange', function (e) {
                if (Ondigo.Utils.getWindowInnerWidth() < 1000) {
                    _scope.$body.removeClass('stickyHeader');

                    if(parseInt(document.documentElement.scrollTop || document.body.scrollTop) > parseInt($('header').outerHeight(), 10)) {
                        _scope.$body.addClass('stickyMobileNav');
                    } else {
                        _scope.$body.removeClass('stickyMobileNav');
                    }
                } else {
                    _scope.$body.removeClass('stickyMobileNav');

                    var mainNavHeight = parseInt($('.mainNavigation').outerHeight(), 10),
                        headerHint = $('.header-hint-wrapper').length ? parseInt($('.header-hint-wrapper').outerHeight(), 10) : 0,
                        headerShrinkFactor = 11,
                        submenuHeight = 0;

                    if($('.mainNavigation li.sub.active').length) {
                        submenuHeight = parseInt($('.mainNavigation li.sub.active .submenu').outerHeight(), 10)
                    }

                    if(parseInt(document.documentElement.scrollTop || document.body.scrollTop) > (headerHint + mainNavHeight + submenuHeight + headerShrinkFactor)) {
                        _scope.$body.addClass('stickyHeader');
                    } else {
                        _scope.$body.removeClass('stickyHeader');
                    }
                }
            });
        }
    };

    // plugin definition
    $.fn.createStickyMobileNav = function () {
        return new Ondigo.StickyMobileNav();
    };
    $.fn.createStickyMobileNav.Constructor = Ondigo.StickyMobileNav;


}(window.jQuery));

$(document).ready(function () {
    'use strict';

    $.fn.createStickyMobileNav();
});