import { SendForm } from './send-form-ajax';
import IMask from 'imask';
import { DomHelper, DomUtilities } from "../general/utils";

class Form {
    static init (form, container, errormessage) {
        let textInputs = form.querySelectorAll('input[type=text]:not(.masked-date), input[type=email], input[type=tel], input[type=password], textarea'),
            maskedDateInputs = form.querySelectorAll('input[type=text].masked-date '),
            selects = form.getElementsByTagName('select'),
            checkboxes = form.querySelectorAll('.checkbox input[type=checkbox]'),
            inputLists = form.getElementsByClassName('inputs-list'),
            fileUploads = form.querySelectorAll('input[type=file]');

        form.setAttribute('novalidate', 'novalidate');

        if(form.classList.contains('multistep')) {
            MultistepForm.init(form);
        }

        if(textInputs.length) {
            for (let textInput of Array.from(textInputs)) {
                Form.initTextInputs(textInput);
            }
        }

        if(maskedDateInputs.length) {
            for (let maskedDateInput of Array.from(maskedDateInputs)) {
                Form.initMaskedDateInput(maskedDateInput);
            }
        }

        if(selects.length) {
            for (let select of Array.from(selects)) {
                Form.initSelects(select);
            }
        }

        if(checkboxes.length) {
            for (let checkbox of Array.from(checkboxes)) {
                Form.initCheckboxes(checkbox);
            }
        }

        if(inputLists.length) {
            for (let inputList of Array.from(inputLists)) {
                Form.initInputLists(inputList);
            }
        }

        if(fileUploads.length) {
            for (let fileUpload of Array.from(fileUploads)) {
                Form.initFileUploads(fileUpload);
            }
        }

        Form.handleFormSubmit(form, container, errormessage);
    }

    static validateFormFields (form) {
        let textInputs = form.querySelectorAll('input[type=text]:not(.masked-date), input[type=email], input[type=tel], input[type=password], textarea'),
            maskedDateInputs = form.querySelectorAll('input[type=text].masked-date '),
            selects = form.getElementsByTagName('select'),
            checkboxes = form.querySelectorAll('.checkbox input[type=checkbox]'),
            inputLists = form.getElementsByClassName('inputs-list'),
            fileUploads = form.querySelectorAll('input[type=file]');

        if(textInputs.length) {
            for (let textInput of Array.from(textInputs)) {
                Form.validateTextInputs(textInput);
            }
        }

        if(maskedDateInputs.length) {
            for (let textInput of Array.from(maskedDateInputs)) {
                Form.validateMaskedDateInput(window.mask, textInput);
            }
        }

        if(selects.length) {
            for (let select of Array.from(selects)) {
                Form.validateSelects(select);
            }
        }

        if(checkboxes.length) {
            for (let checkbox of Array.from(checkboxes)) {
                Form.validateCheckbox(checkbox);
            }
        }

        if(inputLists.length) {
            for (let inputList of Array.from(inputLists)) {
                Form.validateInputList(inputList);
            }
        }

        if(fileUploads.length) {
            for (let fileUpload of Array.from(fileUploads)) {
                Form.validateFileUploads(fileUpload);
            }
        }
    }

    static handleFormSubmit (form, container, errormessage) {
        let submitButton = form.querySelectorAll('button[type=submit]')[0],
            submitButtonText = submitButton.textContent,
            noAjax = form.classList.contains('no-ajax'),
            sendAllowed = false;

        form.onsubmit = function (e) {
            submitButton.setAttribute('disabled', 'disabled');

            submitButton.innerHTML = '<div class="sk-fading-circle">\n' +
                '  <div class="sk-circle1 sk-circle"></div>\n' +
                '  <div class="sk-circle2 sk-circle"></div>\n' +
                '  <div class="sk-circle3 sk-circle"></div>\n' +
                '  <div class="sk-circle4 sk-circle"></div>\n' +
                '  <div class="sk-circle5 sk-circle"></div>\n' +
                '  <div class="sk-circle6 sk-circle"></div>\n' +
                '  <div class="sk-circle7 sk-circle"></div>\n' +
                '  <div class="sk-circle8 sk-circle"></div>\n' +
                '  <div class="sk-circle9 sk-circle"></div>\n' +
                '  <div class="sk-circle10 sk-circle"></div>\n' +
                '  <div class="sk-circle11 sk-circle"></div>\n' +
                '  <div class="sk-circle12 sk-circle"></div>\n' +
                '</div>';

            Form.validateFormFields(form);

            // check for fileupload special + service options from fse facility
            if(form.getElementsByClassName('has-error').length) {

                for(let error of Array.from(form.getElementsByClassName('has-error'))) {

                    if(error.getElementsByClassName('is-required').length) {

                        if(error.classList.contains('visible')) {
                            sendAllowed = false;
                            break;
                        } else {
                            sendAllowed = true;
                        }

                    } else {
                        sendAllowed = false;
                        break;
                    }
                }

                let headerHeight = document.getElementsByTagName('header').length && document.body.classList.contains('stickyHeader') ? document.getElementsByTagName('header')[0].offsetHeight : 0;
                DomUtilities.scrollToY((window.pageYOffset + form.getElementsByClassName('has-error')[0].getBoundingClientRect().top - headerHeight), 300);
            } else {
                sendAllowed = true;
            }

            if(sendAllowed) {

                if(!noAjax) {
                    SendForm.send(form,submitButtonText, container, errormessage, e);
                }
            } else {
                submitButton.removeAttribute('disabled');
                submitButton.innerHTML = submitButtonText;

                e.preventDefault();
                e.stopPropagation();

                return false;
            }
        };
    }

    static initTextInputs (textInput) {
        textInput.addEventListener('focusin', function () {
            this.classList.add('has-value');
        });

        textInput.addEventListener('keyup', function () {
            Form.validateTextInputs(this);
        });

        textInput.addEventListener('focusout', function () {
            Form.handleTextInputLabel(this);
            Form.validateTextInputs(this);
        });
    }

    static initMaskedDateInput (textInput) {
        let mask;

        textInput.classList.add('has-value');

        if(textInput.classList.contains('birthday')) {
            mask = IMask(textInput, {
                mask: Date,
                min: new Date(new Date().getFullYear() - 100, 0, 1),
                max: new Date(new Date().getFullYear(), 0, 1),
                autofix: false,
                lazy: false,
                overwrite: false
            });
        } else {
            mask = IMask(textInput, {
                mask: Date,
                min: new Date(new Date().getFullYear() - 100, 0, 1),
                max: new Date(),
                autofix: false,
                lazy: false,
                overwrite: false
            });
        }

        window.mask = mask;

        textInput.addEventListener('keyup', function () {
            Form.validateMaskedDateInput(mask, this);
        });

        textInput.onblur = function () {
            Form.handleTextInputLabel(textInput);
            Form.validateMaskedDateInput(mask, this);
        };
    }

    static initSelects (select) {
        let _scope = this;

        this.handleSelectLabel(select);

        select.addEventListener('change', function () {
            _scope.handleSelectLabel(this);
            _scope.validateSelects(this);
        });
    }

    static initCheckboxes (checkbox) {
        checkbox.addEventListener('change', function () {
            Form.validateCheckbox(this);
        });
    }

    static initInputLists (inputList) {
        let radioButtons = inputList.querySelectorAll('input[type=radio]'),
            checkBoxes = inputList.querySelectorAll('input[type=checkbox]');

        if(radioButtons.length) {
            for (let radioButton of Array.from(radioButtons)) {
                radioButton.addEventListener('change', function () {
                    Form.validateInputList(inputList);
                });
            }
        }

        if(checkBoxes.length) {
            for (let checkbox of Array.from(checkBoxes)) {
                checkbox.addEventListener('change', function () {
                    Form.validateInputList(inputList);
                });
            }
        }
    }

    static initFileUploads (fileUpload) {
        fileUpload.addEventListener('change', function () {
            Form.handleFileUpload(this);
            Form.validateFileUploads(this);
        });
    }

    static handleTextInputLabel (textInput) {
        if(textInput.value !== '') {
            textInput.classList.add('has-value');
        } else {
            textInput.classList.remove('has-value');
        }
    }

    static handleSelectLabel (select) {
        if(select.selectedIndex > -1 && select.options[select.selectedIndex].value !== '') {
            select.classList.add('has-value');
        } else {
            select.classList.remove('has-value');
        }
    }

    static handleFileUpload (fileUpload) {
        let wrapper = fileUpload.parentNode,
            input = wrapper.querySelectorAll('input[type=text]')[0];

        if(fileUpload.files.length) {
            input.value = fileUpload.files[0].name;
            input.classList.add('has-value');
        } else {
            input.value = '';
            input.classList.remove('has-value');
        }
    }

    static validateTextInputs (textInput) {

        if(textInput.getAttribute('required') === 'required' || (textInput.classList.contains('is-required') && DomHelper.elementExists(DomUtilities.closest(textInput, '.visible')))) {
            if(textInput.value === '') {
                textInput.parentNode.classList.add('has-error');
            } else {
                if(textInput.getAttribute('type') === 'email') {
                    if(!Form.validateMail(textInput)) {
                        textInput.parentNode.classList.add('has-error');
                    } else {
                        textInput.parentNode.classList.remove('has-error');
                    }
                } else if(textInput.getAttribute('type') === 'tel') {
                    if(!Form.validatePhoneNumber(textInput)) {
                        textInput.parentNode.classList.add('has-error');
                    } else {
                        textInput.parentNode.classList.remove('has-error');
                    }
                } else {
                    textInput.parentNode.classList.remove('has-error');
                }
            }
        } else {
            if(textInput.value !== '') {
                textInput.classList.add('has-value-valid');
            } else {
                textInput.classList.remove('has-value-valid');
            }
        }
    }

    static validateMaskedDateInput (mask, textInput) {
        if(textInput.getAttribute('required') === 'required') {
            if(mask.typedValue) {
                textInput.parentNode.classList.remove('has-error');

                let b = mask.value.split('.');
                let year = Number(b[2]),
                    month = Number(b[1]) - 1,
                    day = Number(b[0]),
                    today = new Date(),
                    age = today.getFullYear() - year;

                if (today.getMonth() < month || (today.getMonth() == month && today.getDate() < day)) {
                    age--;
                }
            } else {
                textInput.parentNode.classList.add('has-error');
            }
        } else {
            if(mask.typedValue) {
                textInput.classList.add('has-value-valid');
            } else {
                textInput.classList.remove('has-value-valid');
            }
        }
    }

    static validateMail (textInput) {
        return (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(textInput.value));
    }

    static validatePhoneNumber (textInput) {
        return (/^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(textInput.value));
    }

    static validateSelects (select) {
        if(select.getAttribute('required') === 'required' || (select.classList.contains('is-required') && DomHelper.elementExists(DomUtilities.closest(select, '.visible')))) {
            if(select.value === '') {
                select.parentNode.classList.add('has-error');
            } else {
                select.parentNode.classList.remove('has-error');
            }
        }
    }

    static validateCheckbox (checkbox) {
        if (checkbox.getAttribute('required') === 'required' || (checkbox.classList.contains('is-required') && DomHelper.elementExists(DomUtilities.closest(checkbox, '.visible')))) {
            if(!checkbox.checked) {
                checkbox.parentNode.classList.add('has-error');
            } else {
                checkbox.parentNode.classList.remove('has-error');
            }
        }
    }

    static validateInputList (inputList) {
        let radioButtons = inputList.querySelectorAll('input[type=radio]'),
            checkBoxes = inputList.querySelectorAll('input[type=checkbox]'),
            minCheck = inputList.parentNode.getAttribute('data-minimum');

        if(radioButtons.length) {
            if(radioButtons[0].getAttribute('required') === 'required' || (radioButtons[0].classList.contains('is-required') && DomHelper.elementExists(DomUtilities.closest(radioButtons[0], '.visible')))) {
                let listName = radioButtons[0].getAttribute('name');

                if(inputList.querySelectorAll('input[name="' + listName + '"]:checked').length) {
                    inputList.parentNode.classList.remove('has-error');
                } else {
                    inputList.parentNode.classList.add('has-error');
                }
            }
        }

        if(checkBoxes.length) {
            if(minCheck !== null) {
                let listName = checkBoxes[0].getAttribute('name');

                if(inputList.querySelectorAll('input[name="' + listName + '"]:checked').length < minCheck) {
                    inputList.parentNode.classList.add('has-error');
                } else {
                    inputList.parentNode.classList.remove('has-error');
                }
            }
        }
    }

    static validateFileUploads (fileUpload) {
        if (fileUpload.getAttribute('required') === 'required' || (fileUpload.classList.contains('is-required') && DomHelper.elementExists(DomUtilities.closest(fileUpload, '.visible')))) {
            if(!fileUpload.files.length) {
                fileUpload.parentNode.parentNode.classList.add('has-error');
            } else {
                // max file size 5 MB
                if(fileUpload.files[0].size / 1000000 < 5) {
                    fileUpload.parentNode.parentNode.classList.remove('has-error');
                } else {
                    fileUpload.parentNode.parentNode.classList.add('has-error');
                }
            }
        }
    }
}

class MultistepForm {

    static init (form) {
        let formsteps = form.getElementsByTagName('fieldset'),
            prev = form.querySelectorAll('[data-js="form-step-prev"]')[0],
            submit = form.querySelectorAll('[type="submit"]')[0];

        // activate/show first form step on load
        formsteps[0].classList.add('active');

        // hide submit button on load for multistep forms
        prev.parentNode.classList.add('hidden');
        submit.parentNode.classList.add('hidden');

        MultistepForm.initMultistepNavigation(form);
    }

    static initMultistepNavigation (form) {
        let formstep = form.querySelectorAll('fieldset.active')[0],
            prev = form.querySelectorAll('[data-js="form-step-prev"]')[0],
            next = form.querySelectorAll('[data-js="form-step-next"]')[0];

        prev.addEventListener('click', function () {
            MultistepForm.goPrev(form);
        });

        next.addEventListener('click', function () {
            Form.validateFormFields(formstep);

            if(!formstep.getElementsByClassName('has-error').length) {
                MultistepForm.goNext(form);
            }
        });
    }

    static goNext (form) {
        let formsteps = form.getElementsByTagName('fieldset'),
            next = form.querySelectorAll('[data-js="form-step-next"]')[0],
            prev = form.querySelectorAll('[data-js="form-step-prev"]')[0],
            submit = form.querySelectorAll('[type="submit"]')[0];

        for (let i = 0; i < formsteps.length; i++) {
            let formstep = formsteps[i];

            if(formstep.classList.contains('active')) {
                formstep.classList.remove('active');
                formsteps[i + 1].classList.add('active');

                if(formsteps[formsteps.length - 1].classList.contains('active')) {
                    next.parentNode.classList.add('hidden');
                    submit.parentNode.classList.remove('hidden');
                }

                prev.parentNode.classList.remove('hidden');

                return;
            }
        }
    }

    static goPrev (form) {
        let formsteps = form.getElementsByTagName('fieldset'),
            next = form.querySelectorAll('[data-js="form-step-next"]')[0],
            prev = form.querySelectorAll('[data-js="form-step-prev"]')[0],
            submit = form.querySelectorAll('[type="submit"]')[0];

        for (let i = 0; i < formsteps.length; i++) {
            let formstep = formsteps[i];

            if(formstep.classList.contains('active')) {
                formstep.classList.remove('active');
                formsteps[i - 1].classList.add('active');

                if(formsteps[0].classList.contains('active')) {
                    prev.parentNode.classList.add('hidden');
                }

                next.parentNode.classList.remove('hidden');
                submit.parentNode.classList.add('hidden');

                return;
            }
        }
    }
}

export {
    Form,
    MultistepForm
}
