let sentry;

if (IS_DEV) {
    console.warn('DEV Mode - sentry errors logging deactivated');
    sentry = {
        captureMessage: () => {},
        captureException: () => {},
        configureScope: () => {}
    }
} else {
    sentry = require('./sentry');
}

export default sentry.default ? sentry.default : sentry;