/*global $, jQuery, Modernizr, document, Ondigo, Math */

(function ($) {
    'use strict';

    // class definition
    Ondigo.SwipeHint = function (element) {
        this.$swipeHint = $(element);
        this.$swipeNote = $('.swipeHint .swipeNote');

        this.swiped = false;

        this.init();
    };

    Ondigo.SwipeHint.prototype = {
        init: function () {
            var _scope = this;

            this.$swipeHint.on('scroll', function (e) {
                _scope.$swipeNote.fadeOut();
            });
        }
    };
    // plugin definition
    $.fn.swipeHint = function () {
        return new Ondigo.SwipeHint(this);
    };
    $.fn.swipeHint.Constructor = Ondigo.SwipeHint;

}(window.jQuery));

$(document).ready(function () {
    'use strict';

    var $swipeHint = $('.swipeHint');
    if ($swipeHint.length) {
        $.each($swipeHint, function () {
            $(this).swipeHint();
        });
    }
});