/**
 * Created by mkoehler on 25.07.19.
 */

export default class Team {
    constructor () {
        let _scope = this;
        this.teamWrapper = document.querySelectorAll('.team-wrapper');

        if(this.teamWrapper.length) {
            let teamMembers = this.teamWrapper[0].querySelectorAll('.team-member');

            for (let teamMember of Array.from(teamMembers)) {
                _scope.init(teamMember);
            }

            Team.updateIdentHeight(this.teamWrapper[0]);
            this.handleResize(this.teamWrapper[0]);
        }
    }

    init (member) {
        let content = member.getElementsByClassName('short-content')[0],
            close = member.getElementsByClassName('onIconClose')[0];

        content.addEventListener('click', function () {
            member.getElementsByClassName('team-member-overlay')[0].classList.add('opened');
            Team.overlayBackgroundPositionHandler();
        });

        close.addEventListener('click', function () {
            member.getElementsByClassName('team-member-overlay')[0].classList.remove('opened');
            Team.overlayBackgroundPositionHandler();
        });
    }

    handleResize (wrapper) {
        window.addEventListener('resize', function () {
            setTimeout(function () {
                Team.updateIdentHeight(wrapper);
            }, 100);
        });

        window.addEventListener('orientationchange', function () {
            setTimeout(function () {
                Team.updateIdentHeight(wrapper);
            }, 100);
        });
    }

    static updateIdentHeight (wrapper) {
        if(Ondigo.Utils.getWindowInnerWidth() >= 768) {
            IdentHeight.setIdentHeight('.short-content', wrapper);
        } else {
            IdentHeight.removeIdentHeight('.short-content', wrapper);
        }
    }

    static overlayBackgroundPositionHandler () {
        var overlayClass = 'hasOverlay';
        if (document.body.classList.contains(overlayClass) ) {
            document.body.classList.remove(overlayClass);
            window.scroll(0,parseInt(document.getElementsByClassName('overflowWrapper')[0].style.top) * -1);
            document.getElementsByClassName('overflowWrapper')[0].removeAttribute("style");
        } else {
            document.getElementsByClassName('overflowWrapper')[0].style.top = '-' + window.pageYOffset + 'px';
            document.body.classList.add(overlayClass);
        }
    }
}

class IdentHeight {

    static setIdentHeight (selector, container) {
        let maxHeight,
            heights = [];

        maxHeight = 0;

        for (let el of Array.from(container.querySelectorAll(selector))) {
            el.style.height = '';
            heights.push(el.clientHeight);
        }

        maxHeight = IdentHeight.maxValueInArray(heights);

        for (let el of Array.from(container.querySelectorAll(selector))) {
            el.style.height = maxHeight + 'px';
        }
    }

    static maxValueInArray (array) {
        return Math.max.apply(Math, array);
    }

    static removeIdentHeight (selector, container) {
        for (let el of Array.from(container.querySelectorAll(selector))) {
            el.style.height = '';
        }
    }
}

export {
    IdentHeight
}
