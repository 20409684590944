/*global $, jQuery, Modernizr, document, Ondigo */


/*global $, jQuery, Modernizr, document, Ondigo, Math */

(function ($) {
    'use strict';

    // class definition
    Ondigo.SecondLevelMobile = function (element) {
        this.$mobileNav = $(element);
        this.$dropItem = $('.dropItem', this.$mobileNav);

        this.init();
    };

    Ondigo.SecondLevelMobile.prototype = {
        init: function () {

            if ($('.sub.active', this.$mobileNav).length) {
                $('.sub.active', this.$mobileNav).addClass('opened');
            }

            this.$dropItem.on('click', function (e) {
                if (Ondigo.Utils.getWindowInnerWidth() < 1000) {
                    e.preventDefault();

                    $('.submenu', $(e.target).parent()).slideToggle();
                    $('.submenu', $(e.target).parent()).toggleClass('opened');
                    $(this).parent().toggleClass('opened');
                }
            });
        }
    };
    // plugin definition
    $.fn.secondLevelMobile = function () {
        return new Ondigo.SecondLevelMobile(this);
    };
    $.fn.secondLevelMobile.Constructor = Ondigo.SecondLevelMobile;

}(window.jQuery));

$(document).ready(function () {
    'use strict';

    var $mobileNav = $('.navListWrapper');
    if ($mobileNav.length) {
        $mobileNav.secondLevelMobile();
    }
});