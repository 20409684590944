import '../styles/Style.scss';

require('jquery-touchswipe');
require('picturefill');
require('flexibility');

window.Ondigo = {};
window.Ondigoform = {};

// handle hover for touch devices in css
if (!("ontouchstart" in document.documentElement)) {
  document.documentElement.className += " no-touchevents";
}

import 'lazysizes';
import 'lazysizes/plugins/aspectratio/ls.aspectratio';

require('./polyfills/closest');
require('./polyfills/append');
require('./polyfills/prepend');
require('./polyfills/innerHTML');

// normally we would just import everything the ES6 module way, but it all has to go
// through the script- or legacy-loader so that the globals work.
require('./libs/lazysizes-aspectratio');
require('./libs/lazysizes-modified');

import sentry from './sentry/init.js';

require('./tools/setup');
require('./tools/utils');

require('./helper/adjustBoxHeight');
require('./helper/clickableBox');
require('./helper/ieFontIconLinksEnhancement');
require('./helper/mobileEnhancement');

require('./page/overlay');
require('./page/corona');
require('./page/headerHint');
require('./page/stickyMobileNav');

require('./plugins/formOverlay');

require('./fce/customerQuotes');
require('./fce/rearrangeableContent');
require('./fce/slider');
require('./fce/sliderLightbox');
require('./fce/imageScroller');
require('./fce/lightbox');
require('./fce/swipeHint');
require('./fce/threeColumnImageContent');
require('./fce/tabNavigation');
require('./page/secondLevelMobile');
require('./page/onReady');

import { Usercentrics } from './page/usercentrics';
import ToolTipCookie from './page/toolTipCookie';
import LazyUnveil from './helper/lsUnveil';
import AccordionToggler from './fce/toggler';
import Scroll from './page/scroll';
import Lecturer from './plugins/lecturer';
import Places from './fce/places';
import EducationCourseIntro from './fce/educationCourseIntro';
import EducationCourseFacts from './fce/educationCourseFacts';
import Teaser from './fce/teaser';
import TrainingInformation from './fce/trainingInformation';
import Philosophy from './fce/philosophy';
import JobDetailLocation from './plugins/jobDetailLocation';
import JobFilter from './plugins/jobFilter';
import FormJobSelection from './plugins/formJobSelection';
import SpaceRequestConditions from './plugins/formSpaceRequestConditions';
import FormServices from './plugins/formServices';
import PageMinHeight from './page/setPageMinHeight';
import LightboxImage from './page/lightboxImage';
import Team from './plugins/team';
import NavigationButton from './page/navigationButton';
import Datepicker from './form/datepicker';
import FormInit from './form/form-init';
import ResumeHandler from './form/resume-handler';
import HelpCasketPositioner from './page/help';

Usercentrics.init();

let initToolTipCookie = new ToolTipCookie();
let lazyunveil = new LazyUnveil();
let initTogglerInAccordion = new AccordionToggler();
let scroll = new Scroll();
let lecturer = new Lecturer();
let places = new Places();
let educationCourseIntro = new EducationCourseIntro();
let educationCourseFacts = new EducationCourseFacts();
let philosophy = new Philosophy();
let jobDetailLocation = new JobDetailLocation();
let jobFilter = new JobFilter();
let datepicker = new Datepicker();
let formJobSelection = new FormJobSelection();
let formServices = new FormServices();
let spaceRequestConditions = new SpaceRequestConditions();
let pageMinHeight = new PageMinHeight();
let lightboxImage = new LightboxImage();
let team = new Team();
let teaser = new Teaser();
let trainingInformation = new TrainingInformation();
let navigationButton = new NavigationButton();
let formInit = new FormInit();
let resumeHandler = new ResumeHandler();
let helpCasketPositioner = new HelpCasketPositioner();

