if (/(msie|trident)/i.test(navigator.userAgent)) {
    var innerhtml_get = Object.getOwnPropertyDescriptor(HTMLElement.prototype, "innerHTML").get
    var innerhtml_set = Object.getOwnPropertyDescriptor(HTMLElement.prototype, "innerHTML").set
    Object.defineProperty(HTMLElement.prototype, "innerHTML", {
        get: function () {return innerhtml_get.call (this)},
        set: function(new_html) {
            var childNodes = this.childNodes
            for (var curlen = childNodes.length, i = curlen; i > 0; i--) {
                this.removeChild (childNodes[0])
            }
            innerhtml_set.call (this, new_html)
        }
    })
}