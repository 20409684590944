/*global $, jQuery, Modernizr, document, Ondigo, Ondigoform:true */

(function ($) {
    'use strict';

    // class definition
    Ondigo.CoronaOverlay = function () {
        // hide corona overlay https://project.ondigo.de/projects/319/tasks/35089
        // enable corona overlay https://project.ondigo.de/workload?modal=Task-36614-319
        // hide corona overlay https://project.ondigo.de/projects/319/tasks/40913

        // added checkbox to pagetemplate
        if(!document.querySelectorAll('[data-hide-overlay="1"]').length) {
            //this.init();
        }
    };

    Ondigo.CoronaOverlay.prototype = {
        init: function () {
            $.ajax({
                url: '/corona-hinweis/',
                type: 'GET',
                success: function(data) {
                    var content = $(data).find('article').html(),
                      overlayContent = '<div><div class="logo"></div>' + content + '</div>';

                    if(!sessionStorage.getItem('corona-hint') && !$('body').hasClass('fseFacility')) {
                        $('body').createOverlay($(overlayContent), { additionalCssClasses: 'corona'});
                        sessionStorage.setItem('corona-hint', true);
                    }
                },
                error: function (error) {
                    console.log(error.status + ' - ' + error.statusText);
                }
            });
        }
    };
    // plugin definition
    $.fn.coronaOverlay = function () {
        return new Ondigo.CoronaOverlay(this);
    };
    $.fn.coronaOverlay.Constructor = Ondigo.CoronaOverlay;

}(window.jQuery));

$(document).ready(function () {
    'use strict';

    $.fn.coronaOverlay();
});