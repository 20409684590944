/*global $, jQuery, Modernizr, document, Ondigo, Ondigoform:true */

(function ($) {
    'use strict';

    // class definition
    Ondigo.HeaderHint = function () {
        this.$wrapper = $('.header-hint-wrapper');

        if(this.$wrapper.length) {
            this.init();
        }
    };

    Ondigo.HeaderHint.prototype = {
        init: function () {
            var _scope = this;

            if(!sessionStorage.getItem('header-hint')) {
                this.$wrapper.addClass('visible');
                this.$wrapper.show();

                $('.onIconClose', this.$wrapper).on('click', function () {
                    _scope.$wrapper.removeClass('visible');
                    _scope.$wrapper.slideUp();
                    sessionStorage.setItem('header-hint', true);
                });
            }
        }
    };
    // plugin definition
    $.fn.headerHint = function () {
        return new Ondigo.HeaderHint(this);
    };
    $.fn.headerHint.Constructor = Ondigo.HeaderHint;

}(window.jQuery));

$(document).ready(function () {
    'use strict';

    $.fn.headerHint();
});