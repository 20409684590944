/*global $, jQuery, Modernizr, document, window, Ondigo */

(function ($) {
    'use strict';

    // Class Definition
    Ondigo.CssSlider = function (element) {
        this.$slider = $(element);
        this.$controlsListItems = $('.sliderControls li');
        this.$controls = $('.sliderControls label');
        this.$figcaptions = $('figcaption', this.$cssSlider);
        this.currentSlide = 1;
        this.slideAnimatedWithSwipe = false;
        this.slideCount = this.$controls.length;
        this.swipingPoint = 75;

        this.init();
    };

    Ondigo.CssSlider.prototype = {
        init: function () {
            var _scope = this;

            this.$controls.on('click touchend', function(e) {
                $('#' + $(e.target).attr('for')).prop('checked', 'checked');
                _scope.handleClick(e);
            });

            this.initAutoSlide();

            if (Ondigo.Utils.hasTouchscreen()) {
                this.$slider.swipe({
                    swipeStatus: $.proxy(_scope.swipeSlide, this),
                    threshold: 10,
                    maxTimeThreshold: 2500,
                    fingers: 'all',
                    allowPageScroll: 'vertical'
                });

                $(window).on('orientationchange', function () {
                    clearInterval(_scope.slideInterval);
                    _scope.equalFigcaptionHeight();

                    window.setTimeout(function () {
                        _scope.equalFigcaptionHeight();
                        _scope.initAutoSlide();
                    }, 100);
                });
            } else {
                this.$slider.on('mouseover', function () {
                    clearInterval(_scope.slideInterval);
                });
                this.$slider.on('mouseout', function () {
                    _scope.initAutoSlide();
                });

                $(window).on('resize', function () {
                    clearInterval(_scope.slideInterval);
                    _scope.equalFigcaptionHeight();

                    window.setTimeout(function () {
                        _scope.equalFigcaptionHeight();
                        _scope.initAutoSlide();
                    }, 100);
                });
            }

            this.$controlsListItems.on('click', function (e) {
                $(e.target).find('label').trigger('click');
            });

            this.equalFigcaptionHeight();
        },
        equalFigcaptionHeight: function () {
            var maxHeight = 0;

            this.$figcaptions.removeAttr('style');
            if (Ondigo.Utils.getWindowInnerWidth() < Ondigo.breakpointWide) {
                $.each(this.$figcaptions, function () {
                    if ($(this).height() > maxHeight) {
                        maxHeight = $(this).height();
                    }
                });
                this.$figcaptions.css('height', maxHeight);
            }
        },
        swipeSlide: function (event, phase, direction, distance) {
            if (phase === 'end') {
                if (direction === null) {
                    $(event.target).click();
                }
                else {

                    if (this.currentSlide <= (this.slideCount - 1) && direction === 'left' && this.slideAnimatedWithSwipe === false) {
                        if (distance > this.swipingPoint) {
                            this.slideAnimatedWithSwipe = true;
                            this.currentSlide++;
                            clearInterval(this.slideInterval);
                            $('#slide' + this.currentSlide).click();
                        }
                    } else if ((this.currentSlide > 1) && this.slideCount && direction === 'right' && this.slideAnimatedWithSwipe === false) {
                        if (distance > this.swipingPoint) {
                            this.slideAnimatedWithSwipe = true;
                            this.currentSlide--;
                            clearInterval(this.slideInterval);
                            $('#slide' + this.currentSlide).click();
                        }
                    }

                    this.handleClick();
                    this.slideAnimatedWithSwipe = false;
                    clearInterval(this.slideInterval);
                    this.initAutoSlide();
                }
            }
        },
        initAutoSlide : function () {
            if(this.$slider.hasClass('autoslide')) {
                var _scope = this;

                clearInterval(this.slideInterval);

                this.slideInterval = setInterval(function () {
                    if (_scope.currentSlide < _scope.slideCount) {
                        _scope.currentSlide++;
                    } else {
                        _scope.currentSlide = 1;
                    }
                    $('#slide' + _scope.currentSlide).click();
                    _scope.handleClick();
                }, 6000);
            }
        },
        handleClick : function(e) {
            var time = 0;

            if (e) {
                this.currentSlide = parseInt($(e.target).attr('for').substr($(e.target).attr('for').length - 1));
            }

            while (time < 1000) {
                time = time + 50;
            }
            clearInterval(this.slideInterval);
            this.initAutoSlide();
        }
    };

    // plugin definition
    $.fn.slider = function () {
        return new Ondigo.CssSlider(this);
    };
    $.fn.slider.Constructor = Ondigo.CssSlider;

}(window.jQuery));


$(document).ready(function () {
    'use strict';

    var $slider = $('.slider');
    if ($slider.length) {

        $.each($slider, function () {
            $(this).slider();
        });
    }
});