/*global $, jQuery, Modernizr, document, Ondigo, Math, jwplayer */
import { VideoHandler } from './videoHandler';

(function ($) {
    'use strict';

    // class definition
    var Lightbox = function (element) {
        this.$news = $(element);
        this.init();
    };

    Lightbox.prototype = {
        init: function () {
            $('.thumbnail', this.$news).on('click', function () {
                var $target = $(this),
                    group = $target.data('gallery'),
                    index = parseInt($target.data('index'), 10) || 0,
                    $mediaGallery = $('.lightboxOverlayWrapper[data-gallery="' + group + '"]');

                if (group) {
                    $('body').createOverlay($mediaGallery);
                    $('.overlay .lightbox').createMediaGallery(index);
                }
            });
        }
    };

    // function definition
    $.fn.initLightbox = function () {
        return new Lightbox(this);
    };
    $.fn.initLightbox.Constructor = Lightbox;

}(window.jQuery));


(function ($) {
    'use strict';

    // class definition
    var MediaGallery = function (element, index) {
        this.$mediaGalleryContainer = $(element);

        this.$mediaGalleryOverflow = $('.lightboxOverflow', this.$mediaGalleryContainer);
        this.$previousButton = $('.button.previous', this.$mediaGalleryContainer);
        this.$nextButton = $('.button.next', this.$mediaGalleryContainer);
        this.$slidesWrapper = $('.lightboxSlidesWrapper', this.$mediaGalleryContainer);
        this.$overlayWrapper = $('.overlay-wrapper');
        this.$slides = this.$slidesWrapper.children();

        this.slideOffsetForCloseElement = 0;

        this.sliderWidth = this.$mediaGalleryOverflow.width();

        this.slideCount = this.$slides.length;
        this.activeSlide = index || 0;
        this.lastSlide = this.slideCount - 1;
        this.autostart = this.$slides.eq(this.activeSlide).find('.videoContentLightbox').length ? true : false;

        this.slideAnimatedWithSwipe = false;

        this.animationTiming = 200;

        if (this.slideCount) {
            if (!this.$mediaGalleryContainer.data('initialized')) {
                this.init();
                this.keepDimensions();
            }
            else {
                this.keepDimensions();
                this.handlePreviousAndNextButton();
            }
        }
    };

    MediaGallery.prototype = {
        init: function () {
            var self = this;

            $(document).on('lazyafterunveil', function(e) {
                if ($(e.originalEvent.detail).closest('.overlay').length) {
                    self.removeAspectRatio(e.originalEvent.detail);
                }
            });

            this.$previousButton.on('click', function () {
                self.showPreviousSlide();

            });
            this.$nextButton.on('click', function () {
                self.showNextSlide();
            });

            if(Ondigo.Utils.hasTouchscreen()) {
                $(window).on('orientationchange', function () {
                    self.keepDimensions();
                    window.setTimeout(function () {
                        self.keepDimensions();
                    }, 300);
                });

                this.$slides.swipe({
                    threshold: 10,
                    maxTimeThreshold: 5000,
                    fingers: 'all',
                    allowPageScroll: 'vertical',
                    swipeStatus: function (event, phase, direction, distance) {
                        self.swipeFeedback(event, phase, direction, distance);
                    }
                });
            } else {
                $(window).on('resize', function () {
                    self.keepDimensions();
                });

                $(document).on('keyup', function (e) {
                    if (e.keyCode === 37) {
                        if (self.activeSlide > 0) {
                            self.showPreviousSlide();
                        }
                    }

                    if (e.keyCode === 39) {
                        if (self.activeSlide < self.lastSlide) {
                            self.showNextSlide();
                        }
                    }
                });
            }

            this.handlePreviousAndNextButton();

            // mark each slide with a specific class
            this.$slides.addClass('slide');

            this.$mediaGalleryContainer.data('initialized', true);
        },

        addAspectRatio: function() {
            var $images = this.$mediaGalleryOverflow.find('.lazyload'),
                w = Math.min(960, Ondigo.Utils.getWindowInnerWidth()) - parseInt(this.$overlayWrapper.css('padding-left'),10) * 2,
                $this,
                ratio;

            $images.each(function () {
                $this = $(this);
                ratio = parseFloat($this.data('ratio'));
                $this.css('height', w / ratio);
            });
        },

        removeAspectRatio: function(img) {
            img.style.height = '';
        },

        keepDimensions: function () {
            var $activeSlide = this.$slides.eq(this.activeSlide);

            this.sliderWidth = this.$mediaGalleryOverflow.innerWidth();

            if (Ondigo.Utils.getWindowInnerWidth() > 768) {
                this.sliderWidth -= this.slideOffsetForCloseElement;
            }

            this.$slidesWrapper.width(this.slideCount * this.sliderWidth);

            this.$slides.width(this.sliderWidth);

            this.$slidesWrapper.css({
                marginLeft: -this.activeSlide * this.sliderWidth
            });

            this.determineMaxImageHeightInActiveSlide($activeSlide);
        },
        swipeFeedback: function (event, phase, direction, distance) {
            if (phase === 'move' && this.slideAnimatedWithSwipe === false) {
                if (direction === 'left') {
                    this.$slidesWrapper.css({
                        marginLeft: -this.activeSlide * this.sliderWidth - distance
                    });

                    if (distance > Ondigo.Utils.getWindowInnerWidth() / 5) {
                        this.slideAnimatedWithSwipe = true;

                        if (this.activeSlide < this.$slides.length - 1) {
                            this.activeSlide++;
                            this.animateSlide();
                            this.handlePreviousAndNextButton();
                        }
                    }
                }
                if (direction === 'right') {
                    this.$slidesWrapper.css({
                        marginLeft: -this.activeSlide * this.sliderWidth + distance
                    });

                    if (distance > Ondigo.Utils.getWindowInnerWidth() / 5) {
                        this.slideAnimatedWithSwipe = true;

                        if (this.activeSlide > 0) {
                            this.activeSlide--;
                            this.animateSlide();
                            this.handlePreviousAndNextButton();
                        }
                    }
                }
            }

            if (phase === 'end') {
                this.slideAnimatedWithSwipe = false;

                this.$slidesWrapper.animate({
                        marginLeft: -this.activeSlide * this.sliderWidth
                    },
                    {
                        duration: this.animationTiming,
                        easing: 'linear'
                    });
            }
        },
        determineMaxImageHeightInActiveSlide: function ($activeSlide) {
            this.addAspectRatio();

            var $imageOfActiveSlide = $('img', $activeSlide),
                $slideContentOfActiveSlide = $('.imageWrapper', $activeSlide),
                galleryHeight = parseInt(this.$mediaGalleryOverflow.css('maxHeight'), 10),
                slideContentHeight = $slideContentOfActiveSlide.outerHeight();

            if (galleryHeight / 2 < slideContentHeight) {
                slideContentHeight = Math.floor(galleryHeight / 2);

                $slideContentOfActiveSlide.css({
                    height: slideContentHeight
                });
            }
            else {
                $slideContentOfActiveSlide.css({
                    height: ''
                });
            }

            $imageOfActiveSlide.css({
                maxHeight: galleryHeight - slideContentHeight
            });
        },
        showPreviousSlide: function () {
            this.activeSlide--;

            this.determineMaxImageHeightInActiveSlide(this.$slides.eq(this.activeSlide));
            this.animateSlide();
            this.handlePreviousAndNextButton();
            VideoHandler.stopVideos();
        },
        showNextSlide: function () {
            this.activeSlide++;

            this.determineMaxImageHeightInActiveSlide(this.$slides.eq(this.activeSlide));
            this.animateSlide();
            this.handlePreviousAndNextButton();
            VideoHandler.stopVideos();
        },
        handlePreviousAndNextButton: function () {
            if (this.activeSlide === 0) {
                this.$previousButton.addClass('hidden');
            } else {
                this.$previousButton.removeClass('hidden');
            }

            if (this.activeSlide === this.lastSlide) {
                this.$nextButton.addClass('hidden');
            } else {
                this.$nextButton.removeClass('hidden');
            }
        },
        animateSlide: function () {
            this.$slidesWrapper.animate(
                {
                    marginLeft: -this.activeSlide * this.sliderWidth
                },
                {
                    duration: this.animationTiming,
                    easing: 'linear'
                }
            );
        }
    };

    // function definition
    $.fn.createMediaGallery = function (index) {
        return new MediaGallery(this, index);
    };
    $.fn.createMediaGallery.Constructor = MediaGallery;

}(window.jQuery));


$(document).ready(function () {
    'use strict';

    $('.lightboxWrapper').initLightbox();

    $.each($('.lightbox'), function () {
        $(this).createMediaGallery();
    });
});
