/*global $, jQuery, Modernizr, document, Ondigo */

(function ($) {
    'use strict';

    if (!Ondigo.layoutEnhancements) {
        Ondigo.layoutEnhancements = {};
    }

    // class definition
    Ondigo.layoutEnhancements.ContentRearrangement = function () {
        this.$elements = $('.contentRearrangementAnchor');
        this.isFullscreen = false;

        if (this.$elements.length) {
            this.init();
        }

    };

    Ondigo.layoutEnhancements.ContentRearrangement.prototype = {
        init: function () {
            var _instance = this;

            //add revert anchors
            $.each($('.rearrangeableContentWrapper'), function () {
                $(this).after('<div class="revertAnchor" data-revert-anchor-name="' + $(this).data('content-name') + '"></div>');
            });

            _instance.rearrangeItems();

            $(window).on('resize', function () {
                if (!_instance.isFullscreen) {
                    setTimeout(function () {
                        _instance.rearrangeItems();
                    }, 100);
                }
            });
        },
        rearrangeItems: function () {
            var _instance = this;
            $.each(this.$elements, function () {
                var $itemContainer = $(this),
                    $item = $('[data-content-name="' + $itemContainer.attr('data-content-anchor-name') + '"]'),
                    $revertAnchor = $('[data-revert-anchor-name="' + $itemContainer.attr('data-content-anchor-name') + '"]');

                // check if replacement is needed on load
                _instance.rearrange($itemContainer, $item, $revertAnchor);
            });
        },
        rearrange: function ($itemContainer, $item, $revertAnchor) {
            var windowWidth = Ondigo.Utils.getWindowInnerWidth(),
                breakPoint = parseInt($itemContainer.data('breakpoint-width'), 10);
            if (windowWidth < breakPoint) {
                $itemContainer.append($item);
                if ($revertAnchor.parent().children().length === 1) {
                    $revertAnchor.parent().hide();
                }
            } else {
                $revertAnchor.parent().show();
                $revertAnchor.append($item);
            }
            $item.show();
        }
    };

    // plugin definition
    $.fn.rearrangeContent = function () {
        return new Ondigo.layoutEnhancements.ContentRearrangement();
    };
    $.fn.rearrangeContent.Constructor = Ondigo.layoutEnhancements.ContentRearrangement;

}(window.jQuery));


$(document).ready(function () {
    'use strict';

    $('.contentRearrangementAnchor').rearrangeContent();
});
