import { DomUtilities } from "../general/utils";

export default class TrainingInformation {
    constructor () {
        this.trainingInformationWrapper = document.getElementsByClassName('trainingInformationWrapper');

        if (this.trainingInformationWrapper.length) {
            for (let trainingInformation of Array.from(this.trainingInformationWrapper)) {
                this.init(trainingInformation);
            }
        }
    }

    init (wrapper) {
        let openTrigger = wrapper.querySelectorAll('[data-trigger="true"]'),
            closeTrigger = wrapper.querySelectorAll('.trainingInformationOverlay .onIconClose'),
            overlay = wrapper.getElementsByClassName('trainingInformationOverlay');

        if(openTrigger.length) {

            openTrigger[0].addEventListener('click', function () {

                if(overlay.length) {
                    overlay[0].classList.add('show');
                    DomUtilities.stopPageScroll('hasOverlay');
                }
            });
        }

        if(closeTrigger.length) {

            closeTrigger[0].addEventListener('click', function () {

                if(overlay.length) {
                    overlay[0].classList.remove('show');
                    DomUtilities.enablePageScroll('hasOverlay');
                }
            });
        }
    }
}
