/**
 * Created by mkoehler on 18.09.17.
 */
import { VideoHandler } from '../fce/videoHandler';
import { NumberUpCounter } from '../fce/numberUpCounter';

export default class LightboxImage {
    constructor () {
        let _scope = this;

        this.images = document.getElementsByClassName('lightbox-image');

        if(this.images.length) {

            for (let image of Array.from(this.images)) {
                _scope.init(image);
            }
        }
    }

    init (image) {
        let thumbnail = image.getElementsByClassName('thumbnail-wrapper')[0],
            close = image.getElementsByClassName('onIconClose')[0];

        thumbnail.addEventListener('click', function () {
            image.classList.add('active');
            document.getElementsByClassName('overflowWrapper')[0].style.top = '-' + window.pageYOffset + 'px';
            document.body.classList.add('hasOverlay');
        });

        close.addEventListener('click', function () {
            image.classList.remove('active');
            document.body.classList.remove('hasOverlay');
            window.scroll(0,parseInt(document.getElementsByClassName('overflowWrapper')[0].style.top) * -1);
            document.getElementsByClassName('overflowWrapper')[0].removeAttribute("style");
        });
    }
}