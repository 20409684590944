import { VideoHandler } from '../fce/videoHandler';

(function ($) {
    'use strict';

    var Overlay = function (element, overlayContent, options) {
        this.$container = $(element);
        this.$overlayContent = $(overlayContent);
        this.$overlayBackground = $('.overlayBackground');
        this.$overlay = $('.overlay');
        this.$overlayWrapper = $('.overlayWrapper');
        this.$closeOverlay = $('.closeOverlay');

        this.additionalCssClasses = (options && options.additionalCssClasses) ?  options.additionalCssClasses : '';

        if (this.$container.length) {
            this.init();
        }
    };

    Overlay.prototype = {
        init: function () {
            var self = this;

            $(document).on('keyup', function (e) {
                if (e.keyCode === 27) {
                    self.closeOverlay();
                }
            });

            if (this.$overlayWrapper.length) {
                this.$overlayWrapper.html(this.$overlayContent.html());
                this.updateDomReferences();
                this.showOverlay();
            } else {
                this.appendOverlay();
            }
        },
        updateDomReferences: function () {
            this.$overlay = $('.overlay');
            this.$overlayWrapper = $('.overlayWrapper', this.$overlay);
            this.$closeOverlay = $('.closeOverlay', this.$overlay);
            VideoHandler.initVideoJs();
        },
        appendOverlay: function () {
            var self = this;

            if (this.$overlayBackground.length) {
                this.$overlayBackground.removeClass('hidden');
            } else {
                $('body').append('<div class="overlayBackground"></div>');
                this.$overlayBackground = $('.overlayBackground');
            }

            var $overlay = '<div class="overlay"><div class="overlayContainer ' + this.additionalCssClasses + '"><div class="overlayWrapper">' + this.$overlayContent.html() + '</div><div class="closeOverlay onIconClose"></div></div></div>';
            this.$container.append($overlay);
            this.updateDomReferences();

            this.$closeOverlay.on('click', function () {
                self.closeOverlay();
            });
            this.$overlayBackground.on('click', function () {
                self.closeOverlay();
            });

            this.overlayBackgroundPositionHandler();
        },
        showOverlay: function () {
            this.$overlayBackground.removeClass('hidden');
            this.$overlay.removeClass('hidden');
            this.overlayBackgroundPositionHandler();
        },
        closeOverlay: function () {
            this.$overlayBackground.addClass('hidden');
            this.$overlay.addClass('hidden');
            this.overlayBackgroundPositionHandler();
            VideoHandler.stopVideos();
        },
        overlayBackgroundPositionHandler: function () {
            var overlayClass = 'hasOverlay';
            if(document.body.classList.contains(overlayClass) ) {
                document.body.classList.remove(overlayClass);
                window.scroll(0,parseInt(document.getElementsByClassName('overflowWrapper')[0].style.top) * -1);
                document.getElementsByClassName('overflowWrapper')[0].removeAttribute("style");
            } else {
                document.getElementsByClassName('overflowWrapper')[0].style.top = '-' + window.pageYOffset + 'px';
                document.body.classList.add(overlayClass);
            }
        }
    };

    $.fn.createOverlay = function (overlayContent, options) {
        return new Overlay(this, overlayContent, options);
    };
    $.fn.createOverlay.Constructor = Overlay;

}(window.jQuery));