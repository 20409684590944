/*global $, jQuery, Modernizr, document, Ondigo, Utils */

// global namespace
var Ondigo = {};

/* *********************************************************************
 * Global Variables
 * ****************************************************************** */
Ondigo.breakpointWide = 768;
Ondigo.breakpointMax = 1000;

Ondigo.TabletState = 'TabletState';
Ondigo.DesktopState = 'DesktopState';

// Picture element HTML5 shiv
document.createElement('picture');

window.requestAnimFrame = (function(){
    return  window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function( callback ){window.setTimeout(callback, 1000 / 60);};
})();