class Usercentrics {

  static init () {
    if (window.UC_UI && window.UC_UI.isInitialized()) {
      if(document.querySelectorAll('[data-uc="1"]').length) {
        window.UC_UI.closeCMP();
      }
    } else {
      window.addEventListener('UC_UI_INITIALIZED', function(e) {

        if(document.querySelectorAll('[data-uc="1"]').length) {
          window.UC_UI.closeCMP();
        }
      });
    }
  }
}

export {
  Usercentrics
};