/*global $, jQuery, Modernizr, document, Ondigo, Ondigoform:true */

import { Form } from "../form/form";

(function ($) {
    'use strict';

    // class definition
    Ondigo.Overlay = function (element) {
        this.$button = $(element);
        this.$body = $('body');
        this.$overlay = null;
        this.$overlayShadow = null;

        this.init();
    };

    Ondigo.Overlay.prototype = {
        init: function () {
            var _scope = this,
                html,
                content;

            this.$button.on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $(e.target).attr('disabled', 'disabled');
                $(e.target).addClass('disabled');

                $('.formOverlay').remove();
                $('.shadow').remove();

                html = '<div class="formOverlay"><div class="overlayContent"><div class="innerContent"><span class="loader"></span></div></div></div><div class="shadow"></div>';
                _scope.$body.append(html);
                _scope.$overlay = $('.formOverlay');
                _scope.$overlayShadow = $('.shadow');
                _scope.$overlay.animate({ opacity : 1 });
                _scope.$overlayShadow.fadeIn();
                _scope.overlayBackgroundPositionHandler();

                $.ajax({
                    url: '/contact/',
                    type: 'GET',
                    success: function(data){
                        content = $(data).find('.ondigoform-t3').parent().html() + '<span class="remove onIconClose"></span>';
                        Ondigo.OverlayHandler.appendOverlay(content);
                    }
                });
            });
        },
        overlayBackgroundPositionHandler: function () {
            var overlayClass = 'hasOverlay';
            if(document.body.classList.contains(overlayClass) ) {
                document.body.classList.remove(overlayClass);
                window.scroll(0,parseInt(document.getElementsByClassName('overflowWrapper')[0].style.top) * -1);
                document.getElementsByClassName('overflowWrapper')[0].removeAttribute("style");
            } else {
                document.getElementsByClassName('overflowWrapper')[0].style.top = '-' + window.pageYOffset + 'px';
                document.body.classList.add(overlayClass);
            }
        }
    };
    // plugin definition
    $.fn.overlay = function () {
        return new Ondigo.Overlay(this);
    };
    $.fn.overlay.Constructor = Ondigo.Overlay;

}(window.jQuery));

$(document).ready(function () {
    'use strict';

    window.setTimeout(function() {
        var $openOverlay = $('[data-overlay-content]');
        if ($openOverlay.length) {
            $.each($openOverlay, function () {
                if ($(this).attr('data-overlay-content')) {
                    $(this).overlay();
                }
            });
        }
    }, 200);

});

(function () {
    'use strict';

    Ondigo.OverlayHandler = function () {
        var appendOverlay = function(html) {
                var $overlayContent = $('.formOverlay .innerContent'),
                    overlayForm,
                    errorDomDe = '<h3>Ein unbekannter Fehler ist aufgetreten.</h3>'

                $overlayContent.append(html);
                $('.loader', $overlayContent).remove();

                overlayForm = document.querySelectorAll('.formOverlay .innerContent .ondigoform-t3');

                if (overlayForm.length) {
                    Form.init(overlayForm[0], null, errorDomDe);
                }
                addListeners();
            },
            addListeners = function() {
                var $overlay = $('.formOverlay');

                $overlay.on('click', function (e) {
                    if($(e.target).hasClass('formOverlay') || $(e.target).hasClass('onIconClose')) {
                        e.preventDefault();
                        $overlay.fadeOut();
                        $('.shadow').fadeOut();
                        overlayHandler();
                    }
                });
            },
            overlayHandler = function () {
                var overlayClass = 'hasOverlay';
                if(document.body.classList.contains(overlayClass) ) {
                    document.body.classList.remove(overlayClass);
                    window.scroll(0,parseInt(document.getElementsByClassName('overflowWrapper')[0].style.top) * -1);
                    document.getElementsByClassName('overflowWrapper')[0].removeAttribute("style");
                } else {
                    document.getElementsByClassName('overflowWrapper')[0].style.top = '-' + window.pageYOffset + 'px';
                    document.body.classList.add(overlayClass);
                }
            };

        return {
            appendOverlay : appendOverlay,
            addListeners: addListeners
        };
    }();
}());

