/**
 * Created by mkoehler on 07.02.19.
 */

export default class EducationCourseIntro {
    constructor () {
        let _scope = this;
        this.wrapper = document.querySelectorAll('.row.education-course-intro');

        if(this.wrapper.length) {
            for (let wrapper of Array.from(this.wrapper)) {
                _scope.init(wrapper);
            }
        }
    }

    init (wrapper) {
        let startDateText = wrapper.querySelectorAll('.education-course-start .education-course-info')[0].innerText,
            daysWrapper = wrapper.getElementsByClassName('education-course-days')[0],
            hoursWrapper = wrapper.getElementsByClassName('education-course-hours')[0],
            startDateArr = startDateText.split('.'),
            startDate = new Date(startDateArr[2], startDateArr[1] - 1, startDateArr[0], '8'), // 8 'cause course starts at 8'o clock - TODO fetch start time
            today = new Date(),
            secondsBetween = (startDate.getTime() - today.getTime()) / 1000,
            minutesBetween = secondsBetween / 60,
            hoursBetween = parseInt(minutesBetween / 60, 10),
            daysBetween = 0;

        while(hoursBetween > 24) {

            hoursBetween = hoursBetween - 24;
            daysBetween = daysBetween + 1;
        }

        daysWrapper.innerText = daysBetween < 10 ? '0' + daysBetween : daysBetween;
        hoursWrapper.innerText = hoursBetween < 10 ? '0' + hoursBetween : hoursBetween;
    }
}