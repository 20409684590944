/**
 * Created by mkoehler on 31.01.20.
 */

import { DomUtilities } from '../general/utils';

export default class Teaser {
    constructor () {
        let _scooe = this;

        this.teasers = document.getElementsByClassName('teaser-wrapper');

        if(this.teasers.length) {
            for(let teaser of Array.from(this.teasers)) {
                _scooe.init(teaser);
            }
        }
    }
    init (teaser) {
        let _scope = this;

        window.onload = function () {

            if(DomUtilities.getWindowInnerWidth() < 768) {
                _scope.removeIdentHeight(teaser);
            } else {
                _scope.setIdentHeight(teaser);
            }
        };

        this.handleResize(teaser);
    }

    setIdentHeight (teaser) {
        let maxHeight,
            heights = [],
            wrapper = DomUtilities.closest(teaser, '.row'),
            teaserInWrapper = wrapper.querySelectorAll('.teaser-wrapper .text-wrapper');

        maxHeight = 0;
        this.removeIdentHeight(teaser);

        for (let elem of Array.from(teaserInWrapper)) {
            heights.push(elem.clientHeight);
        }

        maxHeight = DomUtilities.maxValueInArray(heights);

        for (let elem of Array.from(teaserInWrapper)) {
            elem.style.height = maxHeight + 'px';
        }
    }

    removeIdentHeight (teaser) {
        let wrapper = DomUtilities.closest(teaser, '.row'),
            teaserInWrapper = wrapper.querySelectorAll('.teaser-wrapper .text-wrapper');

        for (let elem of Array.from(teaserInWrapper)) {
            elem.style.height = '';
        }
    }

    handleResize (teaser) {
        let _scope = this,
            event = DomUtilities.isTouchDevice() ? 'orientationchange' : 'resize';

        window.addEventListener(event, function () {
            setTimeout(function () {
                if(DomUtilities.getWindowInnerWidth() < 768) {
                    _scope.removeIdentHeight(teaser);
                } else {
                    _scope.setIdentHeight(teaser);
                }
            }, 100);
        });
    }
}
