/*global $, jQuery, Modernizr, document, Ondigo */

$(document).ready(function () {
    'use strict';

    if (location.hash) {
        setTimeout(function() {

            window.scrollTo(0, 0);
        }, 1);
    }

    if (Ondigo.Utils.isIE()) {
        $('body').addClass('isIE');
        $('button.jsButtonSubmit').removeAttr('style');
    }

    $('a[href*="#"]').on('click', function(event){
        if ($(this).hasClass('button') && $(this).closest('.overlayContent').length < 0) {
            event.preventDefault();
            $('html,body').animate({scrollTop:$(this.hash).offset().top}, 600);
        }
    });

    if(window.location.hash) {
        window.setTimeout(function () {
            $('html,body').animate({scrollTop:$(window.location.hash).offset().top}, 1500);
        }, 200);
    }
});