/**
 * Created by mkoehler on 08.05.19.
 */

export default class JobFilter {
    constructor () {
        let _scope = this;
        this.wrappers = document.querySelectorAll('.job-filter-wrapper:not(.featured-jobs-wrapper)');

        if(this.wrappers.length) {
            for(let wrapper of Array.from(this.wrappers)) {
                _scope.init(wrapper);
            }
        }
    }

    init (wrapper) {
        let overlay = wrapper.getElementsByClassName('job-filter-overlay')[0],
            main = document.getElementsByClassName('main')[0],
            filterTriggerButton = wrapper.getElementsByClassName('job-filter-button')[0],
            clearFilterLink = wrapper.getElementsByClassName('clear-filter')[0],
            closeFilterTriggerIcon = overlay.getElementsByClassName('close-overlay')[0],
            closeFilterTriggerButton = overlay.getElementsByClassName('button')[0];

        JobFilter.setFilterWrapperMinHeight(wrapper);
        JobFilter.handleResize(wrapper);

        // close desktop overlay on click outside
        main.addEventListener('click', function (e) {
            let target = e.target || e.srcElement || e.currentTarget;

            if(!wrapper.contains(target) && Ondigo.Utils.getWindowInnerWidth() >= 768) {
                JobFilter.handleOverlayVisibility(overlay, false);
            }
        });

        filterTriggerButton.addEventListener('click', function () {
            if(overlay.getAttribute('aria-expanded') === "true" || overlay.getAttribute('aria-expanded') === true) {
                JobFilter.handleOverlayVisibility(overlay, false);
            } else {
                JobFilter.handleOverlayVisibility(overlay, true);
            }
        });


        clearFilterLink.addEventListener('click', function () {
            JobFilter.clearFilter(wrapper, this);
        });



        // close icon
        closeFilterTriggerIcon.addEventListener('click', function () {
            JobFilter.handleOverlayVisibility(overlay, false);
        });

        // red button in overlay -> X freie Stellen
        closeFilterTriggerButton.addEventListener('click', function () {
            JobFilter.handleOverlayVisibility(overlay, false);
        });

        this.initFilterItems(wrapper);
        JobFilter.setJobItemCounter(wrapper);
        JobFilter.updateFilterByCookie(wrapper);
        JobFilter.updateListByFilter(wrapper, false);
    }

    static setFilterWrapperMinHeight (wrapper) {
        let footer = document.getElementsByClassName('footer')[0];

        if(Ondigo.Utils.getWindowInnerWidth() >= 768) {
            wrapper.style.minHeight = (Ondigo.Utils.getWindowInnerHeight() - parseInt(wrapper.getBoundingClientRect().top, 10) - parseInt(footer.clientHeight, 10)) + 'px';
        }
    }

    static handleResize (wrapper) {
        let overlay = wrapper.getElementsByClassName('job-filter-overlay')[0];

        if(Ondigo.Utils.hasTouchscreen()) {
            window.addEventListener('orientationchange', function () {
                setTimeout(function () {
                    JobFilter.setFilterWrapperMinHeight(wrapper);
                    JobFilter.handleOverlayVisibility(overlay, false);
                }, 100);
            });
        } else {
            window.addEventListener('resize', function () {
                setTimeout(function () {
                    JobFilter.setFilterWrapperMinHeight(wrapper);
                    JobFilter.handleOverlayVisibility(overlay, false);
                }, 100);
            });
        }
    }

    static setFilterTriggerButtonLabel (wrapper, filter) {
        let filterTriggerButton = wrapper.getElementsByClassName('job-filter-button')[0],
            filterCounter = filterTriggerButton.getElementsByClassName('filter-set-count')[0];

        filterCounter.innerHTML = filter;

        if(filter > 0) {
            filterCounter.classList.add('show');
        } else {
            filterCounter.classList.remove('show');
        }
    }

    static setJobItemCounter (wrapper) {
        let currents = wrapper.getElementsByClassName('filter-count-current'),
            currentsPlural = wrapper.querySelectorAll('.job-filter-header-grid .job-plural')[0],
            total = wrapper.getElementsByClassName('filter-count-total')[0];

        for(let current of Array.from(currents)) {
            current.innerHTML = wrapper.querySelectorAll('.job-item:not(.hide-item)').length;
        }

        if(wrapper.querySelectorAll('.job-item:not(.hide-item)').length === 1) {
            currentsPlural.classList.add('hidden');
        } else {
            currentsPlural.classList.remove('hidden');
        }

        if(total.innerHTML === '0' || total.innerHTML === 0) {
            total.innerHTML = wrapper.querySelectorAll('.job-item').length;
        }
    }

    static handleOverlayVisibility (overlay, visible) {
        overlay.setAttribute('aria-expanded', visible);

        if(Ondigo.Utils.getWindowInnerWidth() < 768) {
            if(visible) {
                document.body.classList.add('hasOverlay');
            } else {
                document.body.classList.remove('hasOverlay');
            }
        }
    }

    initFilterItems (wrapper) {
        let overlay = wrapper.getElementsByClassName('job-filter-overlay')[0],
            types = overlay.querySelectorAll('input[type="radio"]'),
            departments = overlay.querySelectorAll('input[type="checkbox"]');

        for (let type of Array.from(types)) {
            type.addEventListener('change', function () {
                JobFilter.updateDepartmentsByType(this.value, wrapper);
                JobFilter.updateListByFilter(wrapper, true);
            });
        }

        for (let department of Array.from(departments)) {
            department.addEventListener('change', function () {
                JobFilter.updateListByFilter(wrapper, true);
            });
        }
    }
    static clearFilter (wrapper, link) {
        let overlay = wrapper.getElementsByClassName('job-filter-overlay')[0],
            types = overlay.querySelectorAll('input[type="radio"]'),
            departments = overlay.querySelectorAll('input[type="checkbox"]'),
            jobListItems = wrapper.getElementsByClassName('job-item'),
            filter = {};

        filter.types = [];
        filter.departments = [];

        // uncheck radios
        for (let type of Array.from(types)) {
            type.checked = false;
        }

        // uncheck checkboxes
        for (let department of Array.from(departments)) {
            department.checked = false;
            department.disabled = false;
        }

        // show all job items in list
        this.showAllItems();

        JobFilter.setFilterTriggerButtonLabel(wrapper, 0);
        Ondigo.Utils.setCookie('fse-jobs-filter', encodeURIComponent(JSON.stringify(filter)), 730);

        // hide clear link
        link.classList.add('hidden');
        JobFilter.setJobItemCounter(wrapper);
    }

    static showAllItems(){
      // show all job items in list
      for(let jobItem of Array.from(jobListItems)) {
        jobItem.classList.remove('hide-item');
      }
    }

    static handleClearFilterLinkVisibility (wrapper) {
        let clearFilterLink = wrapper.getElementsByClassName('clear-filter')[0];

        if(wrapper.querySelectorAll('input[type="radio"]:checked').length || wrapper.querySelectorAll('input[type="checkbox"]:checked').length) {

            if(clearFilterLink.classList.contains('hidden')) {
                clearFilterLink.classList.remove('hidden');
            }
        } else {
            if(!clearFilterLink.classList.contains('hidden')) {
                clearFilterLink.classList.add('hidden');
            }
        }
    }

    static getActiveType (wrapper) {
        let types = wrapper.querySelectorAll('input[type="radio"]:checked'),
            typesArr = [];

        if(types.length) {
            for (let type of Array.from(types)) {
                typesArr.push(type.value);
            }
        }

        return typesArr;
    }

    static getActiveDepartment (wrapper) {
        let departments = wrapper.querySelectorAll('input[type="checkbox"]:checked'),
            departmentsArr = [];

        if(departments.length) {
            for (let department of Array.from(departments)) {
                departmentsArr.push(department.getAttribute('data-company') + '-' + department.value);
            }
        }

        return departmentsArr;
    }

    static updateDepartmentsByType (activeType, wrapper) {
        let activeListItems,
            departments = wrapper.querySelectorAll('input[type="checkbox"]'),
            activeListItemCompanies = [],
            activeListItemDescriptions = [];

        activeListItems=activeType==='all'? wrapper.querySelectorAll('.job-item[data-type]')  : wrapper.querySelectorAll('.job-item[data-type="' + activeType + '"]')

        if(activeType === 'Vollzeit' || activeType === 'Teilzeit') {
            activeListItems = wrapper.querySelectorAll('.job-item[data-type="' + activeType + '"], .job-item[data-type="Voll- oder Teilzeit"]')
        }

        for (let activeListItem of Array.from(activeListItems)) {
            activeListItemCompanies.push(activeListItem.getAttribute('data-company'));
            activeListItemDescriptions.push(activeListItem.getAttribute('data-department'));
        }

        for (let department of Array.from(departments)) {
            if(activeListItemCompanies.includes(department.getAttribute('data-company')) && activeListItemDescriptions.includes(department.value)) {
                department.disabled = false;
            } else {
                department.disabled = true;
                department.checked = false;
            }
        }

        JobFilter.setFilterTriggerButtonLabel(wrapper, (parseInt(wrapper.querySelectorAll('input[type="radio"]:checked').length, 10) + parseInt(wrapper.querySelectorAll('input[type="checkbox"]:checked').length, 10)));
    }

    static safeFilterConfigurationToCookie (types, departments) {
        let filter = {};

        filter.types = types;
        filter.departments = departments;

        Ondigo.Utils.setCookie('fse-jobs-filter', encodeURIComponent(JSON.stringify(filter)), 730);
    }

    static updateFilterByCookie (wrapper) {

        if(Ondigo.Utils.getCookie('fse-jobs-filter').length) {

            let overlay = wrapper.getElementsByClassName('job-filter-overlay')[0],
                types = overlay.querySelectorAll('input[type="radio"]'),
                departments = overlay.querySelectorAll('input[type="checkbox"]'),
                filter = JSON.parse(decodeURIComponent(Ondigo.Utils.getCookie('fse-jobs-filter')));

            for (let type of Array.from(types)) {

                if(type.value === filter.types[0]) {
                    JobFilter.updateDepartmentsByType(type.value, wrapper);
                    type.checked = true;
                }
            }

            for (let department of Array.from(departments)) {

                for (let filtered of Array.from(filter.departments)) {
                    let arr = filtered.split('-'),
                        filterdepartment = arr[1],
                        filtercompany = arr[0];

                    if(department.getAttribute('data-company') === filtercompany && department.value === filterdepartment) {
                        department.checked = true;
                    }
                }
            }

            JobFilter.setFilterTriggerButtonLabel(wrapper, (parseInt(filter.types.length, 10) + parseInt(filter.departments.length, 10)));
        }
    }

    static updateListByFilter (wrapper, userInteraction) {
        let jobListItems = wrapper.getElementsByClassName('job-item'),
            types = JobFilter.getActiveType(wrapper),
            departments = JobFilter.getActiveDepartment(wrapper);

        // set filter by cookie if needed
        if (!userInteraction && Ondigo.Utils.getCookie('fse-jobs-filter').length) {
            let filter = JSON.parse(decodeURIComponent(Ondigo.Utils.getCookie('fse-jobs-filter')));

            types = types.length ? types : filter.types;
            departments = departments.length ? departments : filter.departments;
        }

        // go through job items
        for(let jobItem of Array.from(jobListItems)) {
            let typeEqualsBoth = (types[0] === 'Vollzeit' || types[0] === 'Teilzeit') && jobItem.getAttribute('data-type') === 'Voll- oder Teilzeit';

            // check if any filter is set
            if(types.length || departments.length) {

                // filter by type
                if(types.length) {
                    // filter by type

                    if(types.includes('all') || types.includes(jobItem.getAttribute('data-type')) || typeEqualsBoth) {
                        jobItem.classList.remove('hide-item');
                    } else {
                        jobItem.classList.add('hide-item');
                    }

                    // filter by department and type
                    if(departments.length) {

                        if((types.includes('all') || (types.includes(jobItem.getAttribute('data-type'))) || typeEqualsBoth) && departments.includes(jobItem.getAttribute('data-company') + '-' + jobItem.getAttribute('data-department'))) {
                            jobItem.classList.remove('hide-item');
                        } else {
                            jobItem.classList.add('hide-item');
                        }
                    }
                } else {
                    // filter by department
                    if(departments.includes(jobItem.getAttribute('data-company') + '-' + jobItem.getAttribute('data-department'))) {
                        jobItem.classList.remove('hide-item');
                    } else {
                        jobItem.classList.add('hide-item');
                    }
                }
            }  else {
                jobItem.classList.remove('hide-item');
            }
        }

        JobFilter.safeFilterConfigurationToCookie(types, departments);
        JobFilter.setFilterTriggerButtonLabel(wrapper, (parseInt(types.length, 10) + parseInt(departments.length, 10)));
        JobFilter.handleClearFilterLinkVisibility(wrapper);
        JobFilter.setJobItemCounter(wrapper);
    }
}
