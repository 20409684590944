/**
 * Created by mkoehler on 19.09.17.
 */
import CountUp from 'countup';

class NumberUpCounter {
    static initCounter() {
        let wrappers = document.getElementsByClassName('number-counter-wrapper');

        if(wrappers.length > 0) {
            for (let wrapper of Array.from(wrappers)) {
                NumberUpCounter.countUpNumbersIfInView(wrapper);
            }
        }
    }
    static initReset() {
        let wrappers = document.getElementsByClassName('number-counter-wrapper');

        if(wrappers.length > 0) {
            for (let wrapper of Array.from(wrappers)) {
                NumberUpCounter.resetNumbersOnLoad(wrapper);
            }
        }
    }
    static resetNumbersOnLoad(wrapper) {
        let numbers = wrapper.getElementsByClassName('number-wrapper');

        if(Ondigo.Utils.getWindowInnerWidth() >= 768) {
            for (let numberWrapper of Array.from(numbers)) {
                let counter = numberWrapper.getElementsByClassName('number-label')[0];

                if(counter.innerText !== '0') {
                    counter.innerText = '0';
                }
            }
        }
    }

    static countUpNumbersIfInView(wrapper) {
        let numbers = wrapper.getElementsByClassName('number-wrapper');

        for(let numberWrapper of Array.from(numbers)) {
            let number = numberWrapper.getAttribute('data-counter'),
                counter = numberWrapper.getElementsByClassName('number-label')[0],
                countUp;

            number = number.replace(',', '.');

            if (counter.innerText === '0' || counter.innerText === 0) {
                if (Ondigo.Utils.elementInViewport(wrapper)) {
                    let options = {
                        useEasing: true,
                        useGrouping: true,
                        separator: ',',
                        decimal: ',',
                        prefix: '',
                        suffix: ''
                    };

                    if(number % 1 !== 0) {
                        countUp = new CountUp(counter, 0, number, 1, 4, options);
                    } else {
                        countUp = new CountUp(counter, 0, number, 0, 4, options);
                    }

                    countUp.start();
                }
            }
        }
    }
}

export {
    NumberUpCounter
}