/*global $, jQuery, Modernizr, document, Ondigo */

// OPTIMIZE: Maybe this can be deleted

(function ($) {
    'use strict';

    // class definition
    Ondigo.AdjustBoxHeight = function (element) {
        var that = this;

        this.$columnContainer = $(element);

        $(window).on('load', function () {
            that.handleIdentHeight();
        });

        if (Ondigo.Utils.hasTouchscreen()) {
            $(window).on('orientationchange', function () {
                that.handleIdentHeight();
            });
        } else {
            $(window).on('resize', function () {
                that.handleIdentHeight();
            });
        }

        return {
            updateIdentHeight : function () {
                that.handleIdentHeight();
            },
            removeIdentHeight : function () {
                that.removeIdentHeight();
            }
        };
    };

    Ondigo.AdjustBoxHeight.prototype = {
        handleIdentHeight: function () {
            if (Ondigo.Utils.getWindowInnerWidth() < 768) {
                if (!this.$columnContainer.hasClass('onSmartphoneIdentHeight')) {
                    this.removeIdentHeight();
                } else {
                    this.setIdentHeight();
                }
            } else {
                this.setIdentHeight();
            }
        },
        setIdentHeight: function () {
            $.each(this.$columnContainer, function () {
                var $childElements = $(this).children(),
                    heights = [],
                    maxHeight;

                // add all heights to the array
                $.each($childElements, function () {
                    $(this).css('height','');
                    heights.push($(this).height());
                });

                // get the highest height in the array
                maxHeight = Ondigo.Utils.maxValueInArray(heights);

                // assign the highest height to all elements
                $.each($childElements, function () {
                    $(this).height(maxHeight);
                });
            });
        },
        removeIdentHeight: function () {
            $.each(this.$columnContainer, function () {
                var $childElements = $(this).children();

                // remove all heights to the array
                $.each($childElements, function () {
                    $(this).css('height','');
                });
            });
        }
    };

    // plugin definition
    $.fn.adjustBoxHeight = function () {
        return new Ondigo.AdjustBoxHeight(this);
    };
    $.fn.adjustBoxHeight.Constructor = Ondigo.AdjustBoxHeight;


}(window.jQuery));

$(document).ready(function () {
    'use strict';

    Ondigo.Utils.createIdentHeight();
});