/**
 * Created by mkoehler on 07.02.19.
 */

export default class EducationCourseFacts {
    constructor () {
        let _scope = this;
        this.wrapper = document.querySelectorAll('.education-course-facts');

        if(this.wrapper.length) {
            for (let wrapper of Array.from(this.wrapper)) {
                _scope.init(wrapper);
            }
        }
    }

    init (wrapper) {
        let courseContent = wrapper.getElementsByClassName('education-course-contents')[0],
            courseContentsWrapper = wrapper.getElementsByClassName('course-content-overflow')[0],
            arrow = wrapper.getElementsByClassName('read-more')[0];

        EducationCourseFacts.handleResize(wrapper);

        arrow.addEventListener('click', function () {
            let height = EducationCourseFacts.getCurrentContentHeight(courseContentsWrapper);

            setTimeout(function () {
                courseContentsWrapper.style.maxHeight = height + 20 + 'px';
                courseContent.setAttribute('aria-expanded', true);
            }, 1000);
        });
    }

    static handleResize (wrapper) {
        let courseContent = wrapper.getElementsByClassName('education-course-contents')[0],
            courseContentsWrapper = wrapper.getElementsByClassName('course-content-overflow')[0];

        window.addEventListener('resize', function () {
            setTimeout(function () {
                if(courseContent.getAttribute('aria-expanded') == true) {
                    courseContentsWrapper.style.maxHeight = 'none';
                }
            }, 100);
        });

        window.addEventListener('orientationchange', function () {
            setTimeout(function () {
                if(courseContent.getAttribute('aria-expanded') == true) {
                    courseContentsWrapper.style.maxHeight = 'none';
                }
            }, 100);
        });
    }

    static getCurrentContentHeight(content) {
        content.style.maxHeight = 'none';

        let height = parseInt(content.offsetHeight, 10);
        content.style.maxHeight = '290px';

        return height;
    }
}