/**
 * Created by mkoehler on 22.06.16.
 */
export default class ToolTipCookie {
    constructor () {
        this.overflowWrapper = document.getElementsByClassName('overflowWrapper');
        this.cookieNotes = Ondigo.Utils.getElementByAttribute('data-cookie');

        if(this.overflowWrapper.length) {
            this.init();
        }
    }

    init () {
        let _scope = this;

        for(var i = 0; i < this.cookieNotes.length; i++) {
            var cookieNote = this.cookieNotes[i],
                cookieType = cookieNote.getAttribute('data-cookie'),
                overflowWrapperPadding = this.overflowWrapper[0].style.paddingBottom.length ? parseInt(this.overflowWrapper[0].style.paddingBottom, 10) : 0;

            if(cookieType === 'cookie-message') {
                if (Ondigo.Utils.getCookie('cookie-message') !== 'true') {
                    cookieNote.style.display = 'block';

                    this.overflowWrapper[0].style.paddingBottom = (overflowWrapperPadding + parseInt(cookieNote.offsetHeight, 10)) + 'px';
                    _scope.initCloseButtons(cookieNote);
                } else {
                    _scope.removeTooltip(cookieNote);
                }
            }
        }
    }

    hideTooltip (cookieNote) {
        var _scope = this,
            cookieType = cookieNote.getAttribute('data-cookie');

        Ondigo.Utils.setCookie(cookieType, true, 730);
        cookieNote.classList.add('fadeOut');

        window.setTimeout(function () {
            _scope.overflowWrapper[0].style.paddingBottom = '';
            _scope.removeTooltip(cookieNote);
        }, 500);
    }

    removeTooltip (cookieNote) {
        cookieNote.parentNode.removeChild(cookieNote);
    }

    initCloseButtons (cookieNote) {
        var _scope = this,
            closeIcon = cookieNote.querySelectorAll(".onIconClose")[0];

        closeIcon.addEventListener('click', function () {
            _scope.hideTooltip(cookieNote);
        });
    }
}