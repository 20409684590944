import { Form } from './form';
import { DomUtilities } from '../general/utils';

export default class FormInit {
    constructor () {
        this.forms = document.getElementsByClassName('ondigoform-t3');

        if(this.forms.length) {
            for(let form of Array.from(this.forms)) {
                let container = DomUtilities.closest(form, 'article'),
                    errorDomDe = '<h2>Ein unbekannter Fehler ist aufgetreten.</h2>';

                Form.init(form, container, errorDomDe);
            }
        }
    }
}


