/**
 * Created by mkoehler on 06.02.19.
 */

export default class Lecturer {
    constructor () {
        let _scope = this;
        this.lecturers = document.querySelectorAll('.lecturer-wrapper .lecturer');

        if(this.lecturers.length) {
            for (let lecturer of Array.from(this.lecturers)) {
                _scope.init(lecturer);
            }
        }
    }

    init (lecturer) {
        let _scope = this,
            expertiseLink = lecturer.getElementsByClassName('show-expertises')[0];

        expertiseLink.addEventListener('click', function () {
            _scope.showExpertiseOverlay(this);
        });
    }

    showExpertiseOverlay (clickedLink) {
        let lecturer = Lecturer.closest(clickedLink, '.lecturer'),
            container = Lecturer.closest(lecturer, '.row'),
            overlay = document.createElement('div'),
            markup = '<div class="lecturer-overlay-wrapper"><div class="onIconClose"></div><div class="lecturer-overlay-content"></div></div>';

        if(document.getElementById('lecturer-overlay') === undefined || document.getElementById('lecturer-overlay') === null) {
            overlay.setAttribute('id', 'lecturer-overlay');
            overlay.setAttribute('aria-visible', 'true');
            overlay.innerHTML = markup;
            container.appendChild(overlay);

            this.initOverlayClose(document.getElementById('lecturer-overlay'));
        }

        document.querySelectorAll('#lecturer-overlay .lecturer-overlay-content')[0].innerHTML = '';
        document.querySelectorAll('#lecturer-overlay .lecturer-overlay-content')[0].appendChild(lecturer.cloneNode(true));
        document.getElementById('lecturer-overlay').setAttribute('aria-visible', 'true');
    }

    initOverlayClose (overlay) {
        overlay.getElementsByClassName('onIconClose')[0].addEventListener('click', function () {
            overlay.setAttribute('aria-visible', 'false');
        });
    }

    static closest (el, selector) {
        let matchesFn;

        // find vendor prefix
        ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(function(fn) {
            if (typeof document.body[fn] === 'function') {
                matchesFn = fn;
                return true;
            }
            return false;
        });

        let parent;

        // traverse parents
        while (el) {
            parent = el.parentElement;
            if (parent && parent[matchesFn](selector)) {
                return parent;
            }
            el = parent;
        }

        return null;
    }
}