/**
 * Created by mkoehler on 10.05.19.
 */
import {DomHelper} from "../general/utils";

export default class FormJobSelection {
    constructor () {
        this.wrapper = document.getElementById('row-online-application-job-offer-selection');

        if(DomHelper.elementExists(this.wrapper)) {
            this.init(this.wrapper);
        }
    }

    init (wrapper) {
        let _scope = this,
            overlay = wrapper.getElementsByClassName('job-selection-overlay')[0],
            jobOverlayTrigger = wrapper.getElementsByClassName('job-selection-input')[0],
            closeOverlay = wrapper.getElementsByClassName('close-overlay')[0],
            jobSelection = wrapper.getElementsByClassName('job-selection-btn')[0];

        this.setJobByUrlParam(wrapper);

        jobOverlayTrigger.addEventListener('click', function () {
            _scope.handleOverlayVisibility(overlay, true);
        });

        closeOverlay.addEventListener('click', function () {
            _scope.handleOverlayVisibility(overlay, false);
        });

        jobSelection.addEventListener('click', function () {
            _scope.handleOverlayVisibility(overlay, false);
        });

        this.initJobSelection(wrapper);
    }

    setJobByUrlParam(wrapper) {
        if (Ondigo.Utils.getParameterByName('jid', window.location.href) !== null) {

            let id = Ondigo.Utils.getParameterByName('jid', window.location.href),
                activeJob = wrapper.querySelectorAll('input[data-job-id="' + id + '"]')[0],
                inputLabel = wrapper.getElementsByClassName('job-selection-input-label')[0],
                hiddenInput = document.querySelectorAll('[data-js="job-for"]');

            activeJob.checked = true;
            if(hiddenInput.length) {
                hiddenInput[0].value = id;
            }
            wrapper.classList.add('has-value');
            wrapper.classList.remove('has-error');
            inputLabel.innerHTML = activeJob.getAttribute('data-title');
        }
    }

    initJobSelection (wrapper) {
        let _scope = this,
            overlay = wrapper.getElementsByClassName('job-selection-overlay')[0],
            jobs = wrapper.querySelectorAll('input[type="radio"]'),
            inputLabel = wrapper.getElementsByClassName('job-selection-input-label')[0],
            hiddenInput = document.querySelectorAll('[data-js="job-for"]');

        for(let job of Array.from(jobs)) {
            job.addEventListener('change', function () {
                if(this.checked) {
                    inputLabel.innerHTML = this.getAttribute('data-title');

                    if(hiddenInput.length) {
                        hiddenInput[0].value = this.getAttribute('data-job-id');
                    }

                    wrapper.classList.add('has-value');
                    wrapper.classList.remove('has-error');

                    if(Ondigo.Utils.getWindowInnerWidth() >= 1000) {
                        _scope.handleOverlayVisibility(overlay, false);
                    }
                }
            });
        }
    }

    handleOverlayVisibility (overlay, visible) {
        overlay.setAttribute('aria-expanded', visible);

        if(visible) {
            document.body.classList.add('hasOverlay');
        } else {
            document.body.classList.remove('hasOverlay');
        }
    }
}