/**
 * Created by mkoehler on 18.09.17.
 */
import { VideoHandler } from '../fce/videoHandler';
import { NumberUpCounter } from '../fce/numberUpCounter';

export default class Scroll {
    constructor () {
        this.ticking = false;
        this.scrollDirection = '';
        this.lastScrollPosition = 0;

        this.onScroll = this.onScroll.bind(this);
        this.updateOnPageScroll = this.updateOnPageScroll.bind(this);

        this.init();
    }

    init() {
        VideoHandler.initVideoJs();
        NumberUpCounter.initReset();
        NumberUpCounter.initCounter();

        window.addEventListener('scroll', this.onScroll, false);
    }

    onScroll() {
        if(!this.ticking) {
            window.requestAnimFrame(this.updateOnPageScroll);
            this.ticking = true;
        }
    }

    updateOnPageScroll() {
        let newScrollPosition = window.pageYOffset;

        if (newScrollPosition < this.lastScrollPosition){
            this.scrollDirection = 'up';
        } else{
            this.scrollDirection = 'down';
        }

        this.lastScrollPosition = newScrollPosition;

        NumberUpCounter.initCounter();
        VideoHandler.initVideoJs();

        this.ticking = false;
    }
}